import React, { useEffect, useState } from 'react';
import cascading from '../assests/css/all.module.css';
import Footer from '../common/footer'
import Dashcard from '../components/dashcard';
import Approvecard from '../components/approvecard';
import Vestingcard from "../components/vestingcard";
import Balancecard from '../components/balancecard';
import Header from '../common/header';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

//lib 
import { consolelog } from '../lib/consolelog';
import { getWalletAddress } from '../lib/localStorage'
import { toFixedNumber } from '../lib/toFixedOf'

//hooks
import { getXtokenusage} from '../hooks/useXtoken';

export default function Dashboard() {
    const navigate = useNavigate()
    //selector 
    const { walletaddress } = useSelector((state) => state.wallet)
    const [detailcard, setDetailcard] = useState([
        {
            label: "TOTAL YUM",
            value: "0",
            img: require("../assests/images/folder.png"),
            key :'total'

        },
        {
            label: "AVAILABLE YUM",
            value: "0",
            img: require("../assests/images/unlock.png"),
            key :'available'

        },
        {
            label: "ALLOCATED YUM",
            value: "0",
            img: require("../assests/images/lock.png"),
            key :'allocate'

        },
        {
            label: "REDEEMING YUM",
            value: "0",
            img: require("../assests/images/timer.png"),
            key :'redeem'

        }
    ])
    const [dashdetail, setDashdetail] = useState([
        {
            name: "Dividends",
            desc: "Earn real yield from protocol earnings by staking your YUM here.",
            img: require("../assests/images/divident.png"),
            mybal:"0",
            total:"11,468",
            fees:"0.5",
            path:"/dividends"

        },
        {
            name: "Yield booster",
            desc: "Boost your staking yields by up to +100% by adding YUM to any eligible position.",
            img: require("../assests/images/booster.png"),
            mybal:"0",
            total:"427",
            fees:"0.5",
            path:"/yieldbooster"

        },
        {
            name: "Launchpad",
            desc: "Get perks and benefits from every project on DonutSwap's launchpad by staking your YUM here.",
            img: require("../assests/images/launchpad.png"),
            mybal:"0",
            total:"376",
            fees:"0.5",
            path:"/launchpad"

        },
    ])


    //useEffect
    useEffect(()=>{
        fetchDatas()
    },[walletaddress])

    //get function
    const fetchDatas = async () => {
        try{
            
            if(getWalletAddress()){
                let XtokenTotal = await getXtokenusage(getWalletAddress())
                let CardDetail =  detailcard
                for(let i=0;i<CardDetail.length;i++){
                    let val = CardDetail[i]
                    if( CardDetail[i].key == 'total'){
                        CardDetail[i].value =  parseFloat(XtokenTotal.allocatedAmount)+ parseFloat(XtokenTotal.redeemingAmount) + parseFloat(XtokenTotal.balance)
                    }
                    if(CardDetail[i].key == 'available'){
                        CardDetail[i].value =parseFloat(XtokenTotal.balance)
                    }
                    if(CardDetail[i].key == 'allocate'){
                        CardDetail[i].value =parseFloat(XtokenTotal.allocatedAmount)
                    }
                    if(CardDetail[i].key == 'redeem'){
                        CardDetail[i].value =parseFloat(XtokenTotal.redeemingAmount)
                    }
                    if(i == CardDetail.length-1){
                        setDetailcard(CardDetail)
                    }
                }
                consolelog('XtokenTotal',XtokenTotal,true)
            }
        }catch(err){
            consolelog('fetchDatas__err',err,true)
        }
        
    } 
    return (
        <>
        <Header/>
            <div className={`${cascading.innerpages} ${cascading.yumpage}`}>
                <div className={`container`}>
                    <div className={`row ${cascading.headrow}`}>
                        <p className={`${cascading.pagehead}`}>Dashboard</p>
                        <p className={`${cascading.pagedesc}`}>Convert your DSP, redeem your YUM and manage your YUM plugins allocations.</p>
                    </div>
                    <div className={`row ${cascading.balancesec}`}>
                        {detailcard.map((e) => (

                            <div className={`col-lg-3 mb-4`} >
                             <Balancecard datas={e}/>

                            </div>

                        ))}


                    </div>
                    <div className={`row`}>
                        <div className={`col-lg-5 col-xl-4`}>
                           <Approvecard /> 
                           <Vestingcard />
                        </div>
                        <div className={`offset-xl-1 col-lg-7 col-xl-7`}>
                            {dashdetail.map((e) => (
                                <Dashcard datas={e} navi={()=>navigate(`${e.path}`)} />
                            ))}

                        </div>

                    </div>

                </div>


            </div>



            <Footer />
        </>
    )
}
