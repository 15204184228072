import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/apycalculatorstyle.module.css';
import { FaArrowDown } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";




import { IoMdClose } from "react-icons/io";
export default function Dividentcalculatormodal(props) {
 


  const [dividentcalculatormodal, setdividentCalculatormodal] = useState(true);

  



  return (
    <>
    <div className={`${cascading.networkmodalstyle}`}>
      <Modal centered show={dividentcalculatormodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>Dividends Calculator</h4>
          <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

        </Modal.Header>
        <Modal.Body className={`pt-0 ${cascading.modalclr} ${cascading.modalbody}`}>
          
<div>
          <p className={`${cascading.innerheadwhite}`}>YUM Allocation
          </p>

          <div className={`${cascading.inputsec} my-3`}>
                    <div className={`input-group mb-3`}>
                        <input
                            type="text"
                            className={`form-control`}
                            placeholder="0"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                        />
                        <div className={`input-group-append`} >
                            <button className={` ${cascading.inputspan}`} id="basic-addon1">
                                Max
                            </button>
                        </div>
                    </div>
                    <p className={`${cascading.balsec}`}>balance : 0 YUM</p>
                </div>

                <Button className={`${cascading.btnstyle}`} onClick={props.onHide}>
           Close
          </Button>
   
                </div>
        











{/* <div className='p-3 text-center'>
<button className={`btn ${cascading.connectwalletbtn}`} type='button'>Close</button>

</div> */}







        </Modal.Body>

      </Modal>
    </div>
</>
  )
}

