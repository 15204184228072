import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/apycalculatorstyle.module.css';
import { FaArrowDown } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";




import { IoMdClose } from "react-icons/io";
export default function Allocatemodal(props) {
 


  const [allocatemodal, setAllocatemodal] = useState(true);

  



  return (
    <>
    <div className={`${cascading.networkmodalstyle}`}>
      <Modal centered show={allocatemodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>Allocate YUM</h4>
          <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

        </Modal.Header>
        <Modal.Body className={`pt-0 ${cascading.modalclr} ${cascading.modalbody}`}>
          
<div>
          <p className={`${cascading.innerheadwhite}`}>Amount
          </p>

          <div className={`${cascading.inputsec} my-3`}>
                    <div className={`input-group mb-3`}>
                        <input
                            type="text"
                            className={`form-control`}
                            placeholder="0"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                        />
                        <div className={`input-group-append`} >
                            <button className={` ${cascading.inputspan}`} id="basic-addon1">
                                Max
                            </button>
                        </div>
                    </div>
                    <p className={`${cascading.balsec}`}>balance : 0.00000000123000 YUM</p>
                </div>

                <p className={`${cascading.innerheadwhitetitle} mt-4`}>Estimates
          </p>

          
          <div className='row mt-3'>
            <div className='col-8 col-md-8'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total allocated amount</p>
            </div>
            <div className='col-4 col-md-4'>
              <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>0 YUM</p>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-8 col-md-8'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total allocation share</p>
            </div>
            <div className='col-4 col-md-4'>
              <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>0%</p>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-8 col-md-8'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Daily returns/YUM</p>
            </div>
            <div className='col-4 col-md-4'>
              <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>$0.0082</p>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-8 col-md-8'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total daily returns</p>
            </div>
            <div className='col-4 col-md-4'>
              <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>$0</p>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-8 col-md-8'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Deallocation fee</p>
            </div>
            <div className='col-4 col-md-4'>
              <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>0 YUM</p>
            </div>
          </div>


                <Button className={`${cascading.btnstyle} mt-4`}>
           Allocate
          </Button>

          <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button>

   
                </div>
        











{/* <div className='p-3 text-center'>
<button className={`btn ${cascading.connectwalletbtn}`} type='button'>Close</button>

</div> */}







        </Modal.Body>

      </Modal>
    </div>
</>
  )
}

