import React, { useState } from 'react';
import cascading from '../assests/css/all.module.css';
import Footer from '../common/footer';
import ReactDatatable from '@ashvin27/react-datatable';
import tick from '../assests/images/tick.png';
import pfimg from '../assests/images/roundtable.png'
import Header from '../common/header';
import { useNavigate } from 'react-router-dom';

export default function Launchpad() {
    const navigate = useNavigate()
    const config = {
        button: {
            excel: false,
            print: false,
            extra: false,
        },
        // show_filter:false,
        show_length_menu: false,
        show_pagination: false
    }

    const columns = [
        {
            key: "name",
            text: "Name",
            className: "name",
            align: "left",
            sortable: true,
            cell: record => { 
                return (
                   <>
                   <div className={`${cascading.launchname}`}>
                    <img src={pfimg} alt='img' className={`img-fluid`}/>
                    <div>
                        <p className={`${cascading.name}`}>{record.name}</p>
                        <p className={`${cascading.label}`}>Raising USDC.e</p>
                    </div>
                  
                
                   </div>

                   </>
                );
            }

        },
        {
            key: "hardcap",
            text: "Hardcap",
            className: "hardcap",
            align: "left",
            sortable: true,
            width:80,
            cell: records =>{
                return(
                    <img src={tick} alt='tick' className={`img-fluid`} width={17} height={17}/>
                )
           
            }
        },
        {
            key: "wlstage",
            text: "WL stage",
            className: "wlstage",
            sortable: true,
            width:80,
            cell: records =>{
                return(
                    <img src={tick} alt='tick' className={`img-fluid`} width={17} height={17}/>
                )
           
            }
        },
        {
            key: "status",
            text: "Status",
            className: "status",
            align: "left",
            sortable: true
        },
        {
            key: "total",
            text: "Total raised",
            className: "total",
            sortable: true,
            align: "left"
        },
        {
            key: "allocation",
            text: "Your allocation",
            className: "allocation",
            sortable: true,
            align: "left"
        },

    ];

    const records = [
        {
            "name": "DSP-BNB.e",
            "hardcap": "",
            "wlstage": "",
            "status": "Ended",
            "total": "750,000 USDC.e",
            "allocation": "0"
        },
        {
            "name": "DSP-BNB.e",
            "hardcap": "",
            "wlstage": "",
            "status": "Ended",
            "total": "750,000 USDC.e",
            "allocation": "0"
        },
        {
            "name": "DSP-BNB.e",
            "hardcap": "",
            "wlstage": "",
            "status": "Ended",
            "total": "750,000 USDC.e",
            "allocation": "0"
        },
        {
            "name": "DSP-BNB.e",
            "hardcap": "",
            "wlstage": "",
            "status": "Ended",
            "total": "750,000 USDC.e",
            "allocation": "0"
        },
        {
            "name": "DSP-BNB.e",
            "hardcap": "",
            "wlstage": "",
            "status": "Ended",
            "total": "750,000 USDC.e",
            "allocation": "0"
        },
        {
            "name": "DSP-BNB.e",
            "hardcap": "",
            "wlstage": "",
            "status": "Ended",
            "total": "750,000 USDC.e",
            "allocation": "0"
        },

    ];
    return (
        <>
         <Header/>
            <div className={`${cascading.innerpages} ${cascading.launchpadpage}`}>
                <div className={`container`}>
                    <div className={`row ${cascading.headrow}`}>
                        <p className={`${cascading.pagehead}`}>Launchpad</p>
                        <p className={`${cascading.pagedesc}`}>Custom-built infrastructure for Multi-chain public sales</p>
                    </div>

                    <div className={`${cascading.tabstyle} mt-3 table-responsive`} id='launchpadtable' >

                        <ReactDatatable
                            // data={data}
                            className={`${cascading.datatablestyle}`}
                            config={config}
                            columns={columns}
                            records={records}
                            onRowClicked={()=>navigate("/launchpaddetail")}
                            

                        />
                    </div>



                </div>


            </div>



            <Footer />
        </>
    )
}
