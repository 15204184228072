import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/addliquiditystyle.module.css';
import toast, { Toaster } from 'react-hot-toast';
import Accordion from 'react-bootstrap/Accordion';
import CopyToClipboard from "react-copy-to-clipboard";
import { FaSpinner, FaCheckCircle , FaInfoCircle} from "react-icons/fa";


import { FaArrowDown } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import bnblogo from '../assests/images/bnb.png';
import coinlogo from '../assests/images/coinicon.png';





import { IoMdClose } from "react-icons/io";
export default function Addsuccessmodal(props) {
 
    const [modalsucess, setmodalsuccess] = useState(true);
    const [isadded, setIsadded] = useState(false);
    const [issuccess, setissuccess] = useState(true);


    useEffect(()=>
    {

      setTimeout(()=>
      {
        setIsadded(true);
      }, 2000)
    },[])



  



  return (
    <>
    <div className={`${cascading.networkmodalstyle}`}>
      <Modal centered show={modalsucess} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>Adding Liquidity</h4>
          <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

        </Modal.Header>
        <Modal.Body className={`pt-0 ${cascading.modalclr} ${cascading.modalbody}`}>
          
<div>
  {
    !isadded?
    <>
<div className={`${cascading.imgsec}`}>
                                    <div className={`${cascading.leftimgsec}`}>
                                        <img src={coinlogo} alt='img' className={`img-fluid ${cascading.fromimg}`} />
                                        <img src={bnblogo} alt='img' className={`img-fluid  ${cascading.toimg}`} />
                                    </div>
                                    <p className={`${cascading.coinname}`}>DSP - BNB</p>
                                </div>

                                <div className='text-center'>
                                  <FaSpinner className={`${cascading.loadspinner}`} />
                                </div>

                                <p className={`${cascading.textwhitedesclg} mt-4`}>Adding Liquidity...</p>
                                <p className={`${cascading.textgreydesclg}`}>Confirm tx from your wallet</p>

                                </> 
                                :
                                <>
                                {issuccess?
                                <>
                                 <div className='text-center'>
                                  <FaCheckCircle className={`${cascading.circleplus}`} />
                                </div>

                                <p className={`${cascading.textwhitedesclg} mt-4`}>You've successfully provided
                                <span>~$2.04 of DSP-BNB 
                                  </span>liquidity.</p>
                                <p className={`${cascading.textgreydesclg}`}>Head to the dashboard page or the pool page to check your position.</p>
                                <div className='text-center'>
                                <button className={`${cascading.declabel}`}>View TX</button>
                                </div>
                                </>
                                :
                                <>
                                <div className='text-center'>
                                 <FaInfoCircle className={`${cascading.infoplusred}`} />
                               </div>

                               <p className={`${cascading.textwhitedesclg} mt-4`}>Error while claiming rewards. If the issue persists, please contact our support.</p>
                            
                               </>

  }



</>
   
}
   

</div>




        </Modal.Body>

      </Modal>
    </div>
</>
  )
}

