import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/apycalculatorstyle.module.css';
import { FaArrowDown } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";




import { IoMdClose } from "react-icons/io";
export default function Apycalculatormodal(props) {
 


  const [apycalculatormodal, setapyCalculatormodal] = useState(true);

  



  return (
    <>
    <div className={`${cascading.networkmodalstyle}`}>
      <Modal centered show={apycalculatormodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>APY Calculator</h4>
          <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

        </Modal.Header>
        <Modal.Body className={`pt-0 ${cascading.modalclr} ${cascading.modalbody}`}>
          
<div>
          <p className={`${cascading.innerheadwhite}`}>Deposit USD value
          </p>

          <div className={`${cascading.inputsec} my-3`}>
                    <div className={`input-group mb-3`}>
                        <input
                            type="text"
                            className={`form-control`}
                            placeholder="0"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                        />
                        <div className={`input-group-append`} >
                            <button className={` ${cascading.inputspan}`} id="basic-addon1">
                                Max
                            </button>
                        </div>
                    </div>
                    <p className={`${cascading.balsec}`}>balance : 0 $</p>
                </div>

              <div className='row'>
                <div className='col-12 col-md-5'>
                <p className={`mb-0 ${cascading.innerheadwhite}`}>Lock duration
          </p>  
          <a href="javascript:void(0)" className={`${cascading.themelink}`}>Set Max</a>
                </div>
                <div className='col-12 col-md-7 mt-3 mt-md-0'>
                  <div className='d-flex align-items-center justify-content-between'>
                  <button className={`btn ${cascading.themelink} ${cascading.buttonstyle_ul}`}>
<FaMinus />
                  </button>
                  <div>
                  <p className={`mb-1 text-center ${cascading.textgreydesc}`}>Months</p>
                  <p className={`mb-0 text-center ${cascading.textwhitedesc}`} >0</p>


                  </div>
                  <div>
                  <p className={`mb-1 text-center ${cascading.textgreydesc}`}>Days</p>
                  <p className={`mb-0 text-center ${cascading.textwhitedesc}`} >0</p>


                  </div>
                  <button className={`btn ${cascading.themelink} ${cascading.buttonstyle_ul}`}>
<FaPlus />
                  </button>
                  </div>
                  </div>
              </div>

              <div className='row mt-4'>
                <div className='col-12 col-md-5'>
                <p className={`mb-0 ${cascading.innerheadwhite}`}>YieldBooster
          </p>  
          <a href="javascript:void(0)" className={`${cascading.themelink}`}>Get Max Bonus</a>
                </div>
                <div className='col-12 col-md-7 mt-3 mt-md-0'>
                <div className={`${cascading.inputsec}`}>
                    <div className={`input-group mb-3`}>
                        <input
                            type="text"
                            className={`form-control`}
                            placeholder="0"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                        />
                        <div className={`input-group-append`} >
                            <button className={` ${cascading.inputspan}`} id="basic-addon1">
                                Max
                            </button>
                        </div>
                    </div>
                    <p className={`${cascading.balsec}`}>balance : 0 DSP</p>
                </div>
                  </div>
              </div>
              <p className={`mb-0 ${cascading.innerheadwhite}`}>Estimates
          </p>  
          <div className='row mt-3'>
            <div className='col-8 col-md-8'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Projected total APY</p>
            </div>
            <div className='col-4 col-md-4'>
              <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>7.69%</p>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-8 col-md-8'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Projected yearly earnings</p>
            </div>
            <div className='col-4 col-md-4'>
              <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>$0</p>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-8 col-md-8'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Swap fees APR</p>
            </div>
            <div className='col-4 col-md-4'>
              <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>6.38%</p>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-8 col-md-8'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Farm base APR</p>
            </div>
            <div className='col-4 col-md-4'>
              <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>1.02%</p>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-8 col-md-8'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Farm bonus APR</p>
            </div>
            <div className='col-4 col-md-4'>
              <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>0%</p>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-8 col-md-8'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total APR</p>
            </div>
            <div className='col-4 col-md-4'>
              <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>7.41%</p>
            </div>
          </div>

   
                </div>
        











{/* <div className='p-3 text-center'>
<button className={`btn ${cascading.connectwalletbtn}`} type='button'>Close</button>

</div> */}







        </Modal.Body>

      </Modal>
    </div>
</>
  )
}

