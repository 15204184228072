import React, { useState } from 'react'
import cascading from '../assests/css/all.module.css';
import { Button, ButtonGroup } from 'react-bootstrap';
import { BiRightArrowAlt } from "react-icons/bi";
import { FaMinus, FaPlus} from "react-icons/fa6";
export default function Nextepoch(props) {
 
 
    return (
        <div className={`${cascading.approvecard} mb-4`}>
       
            <div className={`${cascading.innercard} ${cascading.dashcard}`}>
                <p className={`${cascading.head} ${cascading.headfelx}`}>
                   Next Epoch


                </p>
           
                <div className={`${cascading.innercard} mt-3`}>
            <div className='mt-2'>
                <p className={`${cascading.labelname}`}>Min. estimated value</p>
                <p className={`${cascading.value}`}>$0</p>
            </div>
           
            <div className='mt-2'>
                <p  className={`${cascading.labelname}`}>APY</p>
                <p className={`${cascading.value}`}>0 %</p>
            </div>
            <div className='mt-2'>
                <p  className={`${cascading.labelname}`}>Remaining time</p>
                <p className={`${cascading.value}`}>4D 4h 20min 50s</p>
            </div>
        </div>


           


            </div>




        </div>
    )
}
