import contractAddress from '../config/constant/contract'
import { CURRENT_CHAIN_ID } from './useWeb3'
// import { POOLS } from '../config/pools'
import { getPoolChain } from '../config/pools'
import { multicall } from './useMultiCall'
import POOL_ABI from '../config/abi/poolAbi.json'
import BigNumber from 'bignumber.js'
import { GetChainIndex, useWeb3 } from "./useWeb3"
import WBNB_ABI from '../config/abi/wbnbabi.json'
import DOUB_ABI from '../config/abi/DOUBabi.json'
import ERC20_ABI from '../config/abi/ERC20.json'
import { testnetTokens } from '../config/tokens'
import { getTokens } from './useTokens'
import { BLOCKS_PER_YEAR } from '../config/env'
import { TokenInfo } from './useErc20'
import { isEmpty } from '../lib/isEmpty'
import { getWalletAddress } from "../lib/localStorage"
import { UseMasterChef } from './useMasterChef'
import { POOL_DATA, POOL_USER_DATA } from '../constants'

import { EstGas, IsCurrency, IsWrappedCurrency } from './useCommon'

const POOLS = getPoolChain()

const BIG_ZERO = new BigNumber(0)
const BIG_TEN = new BigNumber(10)

// const nonBnbPools = POOLS.filter((pool) => pool.stakingToken.symbol !== 'BNB')
// const bnbPools = POOLS.filter((pool) => pool.stakingToken.symbol === 'BNB')

const nonBnbPools = POOLS.filter((pool) => !IsCurrency(pool.stakingToken))
const bnbPools = POOLS.filter((pool) => IsCurrency(pool.stakingToken))
const nonMasterPools = POOLS.filter((pool) => pool.sousId !== 0)

export const getPoolAddress = (address) => {
    try {
        const CHAIN_ID = CURRENT_CHAIN_ID()
        let Pooladdress = address[`${CHAIN_ID}`]
        return Pooladdress

    } catch (err) {
        console.log("getPoolAddress")
    }
}

export const UsePool = async (contractAddress) => {
    const web3 = await useWeb3();
    const contract = new web3.eth.Contract(POOL_ABI, getPoolAddress(contractAddress));
    console.log("contract", contract);
    return contract;
}


export const GetBlocknumber = async () => {
    try {
        const web3 = await useWeb3()
        const block = web3.eth.getBlockNumber()
        return block

    } catch (err) {
        console.log("getPoolAddress")
    }
}




/**
 * Returns the total number of pools that were active at a given block
 */
export const getActivePools = async () => {
    console.log("POOLS", POOLS)
    let block = GetBlocknumber()
    const eligiblePools = POOLS
        .filter((pool) => pool.sousId !== 0)
        .filter((pool) => pool.isFinished === false || pool.isFinished === undefined)
    const blockNumber = block
    const startBlockCalls = eligiblePools.map(({ contractAddress }) => ({
        address: getPoolAddress(contractAddress),
        name: 'startBlock',
    }))
    const endBlockCalls = eligiblePools.map(({ contractAddress }) => ({
        address: getPoolAddress(contractAddress),
        name: 'bonusEndBlock',
    }))

    const startBlocks = await multicall(POOL_ABI, startBlockCalls)
    const endBlocks = await multicall(POOL_ABI, endBlockCalls)
    // const blockNumber = await multicall(POOL_ABI, blockNumberCalls)

    console.log("Calll", startBlocks, endBlocks)

    return eligiblePools.reduce((accum, poolCheck, index) => {
        console.log("eligiblePools")
        const startBlock = startBlocks[index] ? new BigNumber(startBlocks[index]).toNumber() : null
        const endBlock = endBlocks[index] ? new BigNumber(endBlocks[index]).toNumber() : null
        console.log("startBlock", startBlock, endBlock)

        console.log("conditions", !startBlock || !endBlock, startBlock > blockNumber || endBlock < blockNumber, poolCheck)
        if (!startBlock || !endBlock) {
            console.log('if1')
            return accum
        }

        if (startBlock > blockNumber || endBlock < blockNumber) {
            console.log('if2')
            return accum
        }
        return [...accum, poolCheck]
    }, [])
}


export const fetchPoolsPublicDataAsync = async (dispatch) => {
    try{
    console.log('fetchPoolsTotalStaking', POOLS)
    let PoolConfig = JSON.stringify(POOLS)
    PoolConfig = JSON.parse(PoolConfig)
    let Pools = await GetPoolTokenDetail(PoolConfig)
    console.log("Pools_Pools", Pools)

    let currentBlock = await GetBlocknumber()
    console.log("currentBlock", currentBlock)

    const blockLimits = await fetchPoolsBlockLimits()
    console.log("blockLimits", blockLimits)

    const totalStakings = await fetchPoolsTotalStaking(PoolConfig)
    console.log("totalStakings", totalStakings)



    let farms = JSON.parse(localStorage.getItem("Farms"))
    const prices = getTokenPricesFromFarm(farms)
    console.log("prices", prices)

    // const liveData = Pools.map(async(pool) => {

    let liveData = []
    for (let i = 0; i < Pools.length; i++) {
        let pool = Pools[i]
        const stakingLimit = await fetchPoolStakingLimit(pool.sousId)
        console.log("stakingLimit", stakingLimit)

        const blockLimit = blockLimits.find((entry) => entry.sousId === pool.sousId)
        console.log("blockLimit", blockLimit,currentBlock)

        const totalStaking = totalStakings.find((entry) => entry.sousId === pool.sousId)
        console.log("totalStaking", totalStaking)

        const isPoolEndBlockExceeded = currentBlock > 0 && blockLimit ? currentBlock > Number(blockLimit.endBlock) : false
        console.log("isPoolEndBlockExceeded", isPoolEndBlockExceeded)

        const isPoolFinished = pool.isFinished || isPoolEndBlockExceeded
        console.log("isPoolFinished", isPoolFinished)

        const stakingTokenAddress = pool.stakingToken.address ? pool.stakingToken.address.toLowerCase() : null
        console.log("stakingTokenAddress", stakingTokenAddress, pool.stakingToken)

        const stakingTokenPrice = stakingTokenAddress ? prices[stakingTokenAddress] : 0
        console.log("stakingTokenPrice", stakingTokenPrice)

        const earningTokenAddress = pool.earningToken.address ? pool.earningToken.address.toLowerCase() : null
        console.log("earningTokenAddress", earningTokenAddress)

        const earningTokenPrice = earningTokenAddress ? prices[earningTokenAddress] : 0
        console.log("earningTokenPrice", earningTokenPrice,isPoolFinished)

        const apr = !isPoolFinished
            ? getPoolApr(
                stakingTokenPrice,
                earningTokenPrice,
                getBalanceAmount(new BigNumber(totalStaking.totalStaked), pool.stakingToken.decimal),
                parseFloat(pool.tokenPerBlock),
            )
            : 0.0
        console.log("apr", apr)
        pool.isFinished=isPoolFinished
        let obj = {
            ...blockLimit,
            ...totalStaking,
            stakingLimit: stakingLimit.toNumber(),
            stakingTokenPrice,
            earningTokenPrice,
            apr,
            isFinished: isPoolFinished,
            ...pool
        }
        console.log(obj,"liveData")
        liveData.push(obj)
        // console.log("liveData",liveData)
        // return 
    }
    dispatch({
        type:POOL_DATA,
        payload:liveData
    })
    return liveData
}
catch(err){
    console.log(err, "getPools__err")
    return false

}
}


export const getBalanceAmount = (amount, decimals) => {
    console.log("getBalanceAmount", amount, decimals)
    return new BigNumber(amount).dividedBy(BIG_TEN.pow(decimals))
}



export const fetchPoolsBlockLimits = async () => {
    const poolsWithEnd = POOLS.filter((p) => p.sousId !== 0)
    const callsStartBlock = poolsWithEnd.map((poolConfig) => {
        return {
            address: getPoolAddress(poolConfig.contractAddress),
            name: 'startBlock',
        }
    })
    const callsEndBlock = poolsWithEnd.map((poolConfig) => {
        return {
            address: getPoolAddress(poolConfig.contractAddress),
            name: 'bonusEndBlock',
        }
    })
    const callsdepositFee = poolsWithEnd.map((poolConfig) => {
        return {
            address: getPoolAddress(poolConfig.contractAddress),
            name: 'depositFee',
        }
    })

    console.log(callsStartBlock, "callsStartBlock");

    const starts = await multicall(POOL_ABI, callsStartBlock)
    const ends = await multicall(POOL_ABI, callsEndBlock)
    const depositFee = await multicall(POOL_ABI, callsdepositFee)

    return poolsWithEnd.map((poolData, index) => {
        const startBlock = starts[index]
        const endBlock = ends[index]
        const depFee = depositFee[index]
        return {
            sousId: poolData.sousId,
            startBlock: new BigNumber(startBlock).toJSON(),
            endBlock: new BigNumber(endBlock).toJSON(),
            depositFee: new BigNumber(depFee).div(100).toJSON()
        }
    })
}

export const fetchPoolsTotalStaking = async (Pools) => {
    // console.log('fetchPoolsTotalStaking',POOLS)
    // let PoolConfig =  JSON.stringify(POOLS)
    // PoolConfig =  JSON.parse(PoolConfig)
    // let Pools = await GetPoolTokenDetail(PoolConfig)
    // console.log("Pools_Pools", Pools)


    // const nonBnbPools = Pools.filter((p) => p.stakingToken.symbol !== 'BNB')
    const nonBnbPools = Pools.filter((p) => !IsCurrency(p.stakingToken))
    console.log("nonBnbPools", nonBnbPools)

    // const bnbPool = Pools.filter((p) => p.stakingToken.symbol === 'BNB')
    const bnbPool = Pools.filter((p) => IsCurrency(p.stakingToken))
    console.log("bnbPool", bnbPool)


    const callsNonBnbPools = nonBnbPools.map((poolConfig) => {
        console.log("callsNonBnbPools", poolConfig.stakingToken.address)

        return {
            address: poolConfig.stakingToken.address,
            name: 'balanceOf',
            params: [getPoolAddress(poolConfig.contractAddress)],
        }

    })

    const callsBnbPools = bnbPool.map((poolConfig) => {
        console.log("callsBnbPools", getTokens()?.find((val) => (IsWrappedCurrency(val))).address)
        // getTokens()?.find((val) => (val?.symbol == 'WBNB')).address
        return {
            address: getTokens()?.find((val) => (IsWrappedCurrency(val))).address,
            name: 'balanceOf',
            params: [getPoolAddress(poolConfig.contractAddress)],
        }

    })

    const nonBnbPoolsTotalStaked = await multicall(DOUB_ABI, callsNonBnbPools)
    console.log("nonBnbPoolsTotalStaked", nonBnbPoolsTotalStaked)

    const bnbPoolsTotalStaked = await multicall(WBNB_ABI, callsBnbPools)
    console.log("bnbPoolsTotalStaked", bnbPoolsTotalStaked)


    return [
        ...nonBnbPools.map((p, index) => ({
            sousId: p.sousId,
            totalStaked: !isEmpty(nonBnbPoolsTotalStaked[index]) ? new BigNumber(nonBnbPoolsTotalStaked[index]).toJSON() : 0,
        })),
        ...bnbPool.map((p, index) => ({
            sousId: p.sousId,
            totalStaked: !isEmpty(bnbPoolsTotalStaked[index]) ? new BigNumber(bnbPoolsTotalStaked[index]).toJSON() : 0,
        })),
    ]
}

export const getTokenPricesFromFarm = (farms) => {
    return farms.reduce((prices, farm) => {
        const quoteTokenAddress = farm.quoteTokenAddress.toLocaleLowerCase()
        const tokenAddress = farm.tokenAddress.toLocaleLowerCase()
        /* eslint-disable no-param-reassign */
        if (!prices[quoteTokenAddress]) {
            prices[quoteTokenAddress] = new BigNumber(farm.quoteTokenPriceBusd).toNumber()
        }
        if (!prices[tokenAddress]) {
            prices[tokenAddress] = new BigNumber(farm.tokenPriceBusd).toNumber()
        }
        /* eslint-enable no-param-reassign */
        return prices
    }, {})
}


export const getPoolApr = (stakingTokenPrice, rewardTokenPrice, totalStaked, tokenPerBlock) => {
    console.log("getPoolApr", stakingTokenPrice, rewardTokenPrice, totalStaked, tokenPerBlock)

    const totalRewardPricePerYear = new BigNumber(rewardTokenPrice).times(tokenPerBlock).times(BLOCKS_PER_YEAR)
    console.log("totalRewardPricePerYear", totalRewardPricePerYear)

    const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(totalStaked)
    console.log("totalStakingTokenInPool", totalStakingTokenInPool)

    const apr = totalRewardPricePerYear / (totalStakingTokenInPool).times(100)
    console.log("apr_apr", apr)

    return isNaN(apr) || !isFinite(apr) ? 0.00 : apr
}

export const getSouschefV2Contract = (id) => {
    const config = POOLS.find((pool) => pool.sousId === id)
    return GetContract(POOL_ABI, getPoolAddress(config.contractAddress))
}
export const GetContract = async (abi, address) => {
    const web3 = await useWeb3();
    try {
        console.log("POOL_ABI")
        const contract = new web3.eth.Contract(abi, web3.utils.toChecksumAddress(address));
        console.log("Contract", contract)
        return contract;
    } catch (err) {
        console.log(err, "POOL_ABI__err")
    }
}
export const fetchPoolStakingLimit = async (sousId) => {
    try {

        const sousContract = await getSouschefV2Contract(sousId)
        console.log("sousContract", sousContract)
        const stakingLimit = await sousContract.methods.poolLimitPerUser().call()
        console.log("fetchPoolStakingLimit", stakingLimit.toString())
        return new BigNumber(stakingLimit.toString())
    } catch (error) {
        console.log("fetchPoolStakingLimit_errr", error)

        return BIG_ZERO
    }
}

export const fetchPoolsStakingLimits = async () => {


    let poolsWithStakingLimit = 1
    let Pools = await GetPoolTokenDetail(POOLS)
    console.log("Pools_Pools", Pools)

    // const validPools = Pools
    //     .filter((p) => p.stakingToken.symbol !== 'BNB' && !p.isFinished)
    //     .filter((p) => !poolsWithStakingLimit.includes(p.sousId))
    const validPools = Pools
        .filter((p) => !IsCurrency(p.stakingToken) && !p.isFinished)
        .filter((p) => !poolsWithStakingLimit.includes(p.sousId))


    console.log("validPools", validPools)

    // Get the staking limit for each valid pool
    // Note: We cannot batch the calls via multicall because V1 pools do not have "poolLimitPerUser" and will throw an error
    const stakingLimitPromises = validPools.map((validPool) => fetchPoolStakingLimit(validPool.sousId))
    const stakingLimits = await Promise.all(stakingLimitPromises)

    return stakingLimits.reduce((accum, stakingLimit, index) => {
        return {
            ...accum,
            [validPools[index].sousId]: stakingLimit,
        }
    }, {})
}

export const GetPoolTokenDetail = async (poolConfig) => {
    console.log('GetPoolTokenDetail', poolConfig)
    try {
        let PoolConfig = [...poolConfig]
        for (let i = 0; i < PoolConfig.length; i++) {
            let data = PoolConfig[i]
            let stakingTokenDetails = await TokenInfo(data.stakingToken)
            let earningTokenDetails = await TokenInfo(data.earningToken)
            console.log("GetPlooTokenDetail", stakingTokenDetails, earningTokenDetails)
            PoolConfig[i]['stakingToken'] = stakingTokenDetails
            PoolConfig[i]['earningToken'] = earningTokenDetails

            if (poolConfig.length - 1 == i) {
                console.log("poolConfig", PoolConfig)
                return PoolConfig
            }
        }
    } catch (err) {
        console.log(err, "GetPlooTokenDetail__err")
    }
}


export const fetchPoolsUserDataAsync = async (dispatch) => {
    try {
        let account = getWalletAddress()
        const allowances = await FetchPoolsAllowance(account)
        console.log("allowances", allowances)

        const stakingTokenBalances = await FetchUserBalances(account)
        console.log("stakingTokenBalances", stakingTokenBalances)

        const stakedBalances = await FetchUserStakeBalances(account)
        console.log("stakedBalances", stakedBalances)

        const pendingRewards = await FetchUserPendingRewards(account)
        console.log("pendingRewards", pendingRewards)


        const userData = POOLS.map((pool) => ({
            sousId: pool.sousId,
            allowance: allowances[pool.sousId],
            stakingTokenBalance: stakingTokenBalances[pool.sousId],
            stakedBalance: stakedBalances[pool.sousId],
            pendingReward: pendingRewards[pool.sousId],
        }))
        console.log("userData", userData)
        dispatch({
            type:POOL_USER_DATA,
            payload:userData
        })
        return userData
    } catch (err) {
        console.log("fetchPoolsUserDataAsync", err)
    }
}

export const FetchPoolsAllowance = async (account) => {
    console.log("account", account, nonBnbPools)

    try {
        const calls = nonBnbPools.map((pool) => ({
            address: pool.stakingToken,
            name: 'allowance',
            params: [account, getPoolAddress(pool.contractAddress)],
        }))

        console.log(calls, 'FetchPoolsAllowance')
        const allowances = await multicall(ERC20_ABI, calls)
        console.log("allowances", allowances)

        return nonBnbPools.reduce(
            (acc, pool, index) => ({ ...acc, [pool.sousId]: new BigNumber(allowances[index]).toJSON() }),
            {},
        )
    }
    catch (err) {
        console.log("fetchPoolsAllowance", err)
    }
}

export const FetchUserBalances = async (account) => {
    try {
        // Non BNB pools
        const web3 = await useWeb3();

        const calls = nonBnbPools.map((pool) => ({
            address: pool.stakingToken,
            name: 'balanceOf',
            params: [account],
        }))
        const tokenBalancesRaw = await multicall(ERC20_ABI, calls)
        const tokenBalances = nonBnbPools.reduce(
            (acc, pool, index) => ({ ...acc, [pool.sousId]: new BigNumber(tokenBalancesRaw[index]).toJSON() }),
            {},
        )

        // BNB pools
        const bnbBalance = await web3.eth.getBalance(account)
        const bnbBalances = bnbPools.reduce(
            (acc, pool) => ({ ...acc, [pool.sousId]: new BigNumber(bnbBalance.toString()).toJSON() }),
            {},
        )

        return { ...tokenBalances, ...bnbBalances }
    }
    catch (err) {
        console.log("fetchPoolsAllowance", err)
    }
}

export const FetchUserStakeBalances = async (account) => {
    try {
        const calls = nonMasterPools.map((p) => ({
            address: getPoolAddress(p.contractAddress),
            name: 'userInfo',
            params: [account],
        }))
        const userInfo = await multicall(POOL_ABI, calls)
        console.log("userInfo", userInfo)
        const stakedBalances = nonMasterPools.reduce(
            (acc, pool, index) => ({
                ...acc,
                [pool.sousId]: new BigNumber(userInfo[index].amount._hex).toJSON(),
            }),
            {},
        )

        // Cake / Cake pool
        let masterContract = await UseMasterChef()

        const { amount: masterPoolAmount } = await masterContract?.methods?.userInfo('0', account).call()

        return { ...stakedBalances, 0: new BigNumber(masterPoolAmount.toString()).toJSON() }
    }
    catch (err) {
        console.log("fetchPoolsAllowance", err)
    }
}

export const FetchUserPendingRewards = async (account) => {
    try {
        const calls = nonMasterPools.map((p) => ({
            address: getPoolAddress(p.contractAddress),
            name: 'pendingReward',
            params: [account],
        }))
        const res = await multicall(POOL_ABI, calls)
        const pendingRewards = nonMasterPools.reduce(
            (acc, pool, index) => ({
                ...acc,
                [pool.sousId]: new BigNumber(res[index]).toJSON(),
            }),
            {},
        )
        let masterContract = await UseMasterChef()
        const pendingReward = await masterContract?.methods?.pendingDSP('0', account).call()
        console.log("pendingReward", pendingReward)
        return { ...pendingRewards, 0: new BigNumber(pendingReward).toJSON() }
    }
    catch (err) {
        console.log("fetchPoolsAllowance", err)
    }
}


//Stake
export const Deposit = async (amount, contractAddress) => {
    const web3 = await useWeb3()
    try {
        console.log("Deposit", amount)
        // const spenderAddress = getPoolAddress()
        const account = getWalletAddress()
        let contract = await UsePool(contractAddress)
        let params = [amount.toString()]
        const { gasLimit, gasPrice } = await EstGas(params, POOL_ABI, getPoolAddress(contractAddress), 'deposit', account)
        let result = await contract.methods.deposit(amount.toString()).send({ from: web3.utils.toChecksumAddress(account), gasLimit: gasLimit, gasPrice: gasPrice })
        console.log("result", result)
        if (result) {
            // approvelocal(Approve.transactionHash)
            return true
        }
    } catch (err) {
        console.log("Deposit__err", err, true)
        return false
    }
}

//unstake
export const UnStake = async (amount, contractAddress) => {
    const web3 = await useWeb3()
    try {
        console.log("UnStake", amount)
        const account = getWalletAddress()
        let contract = await UsePool(contractAddress)
        let params = [amount.toString()]
        const { gasLimit, gasPrice } = await EstGas(params, POOL_ABI, getPoolAddress(contractAddress), 'withdraw', account)
        let result = await contract.methods.withdraw(amount.toString()).send({ from: web3.utils.toChecksumAddress(account), gasLimit: gasLimit, gasPrice: gasPrice })
        console.log("result", result)
        if (result) {
            // approvelocal(Approve.transactionHash)
            return true
        }
    } catch (err) {
        console.log("Harvest__err", err, true)
        return false
    }
}


export const getPoolBlockInfo = async(startBlock, endBlock, isFinished) => {
    console.log("getPoolBlockInfo",startBlock, endBlock, isFinished)
    const currentBlock = await GetBlocknumber()
    const shouldShowBlockCountdown = Boolean(!isFinished && startBlock && endBlock)
    const blocksUntilStart = Math.max(startBlock - currentBlock, 0)
    const blocksRemaining = Math.max(endBlock - currentBlock, 0)
    const hasPoolStarted = blocksUntilStart === 0 && blocksRemaining > 0
    const blocksToDisplay = hasPoolStarted ? blocksRemaining : blocksUntilStart
    console.log("getPoolBlockInfo1",shouldShowBlockCountdown, blocksUntilStart, blocksRemaining, hasPoolStarted, blocksToDisplay,currentBlock)

    return { shouldShowBlockCountdown, blocksUntilStart, blocksRemaining, hasPoolStarted, blocksToDisplay }
}
