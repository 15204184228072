import React, { useState } from 'react'
import cascading from '../assests/css/all.module.css';
import { Button, ButtonGroup } from 'react-bootstrap';
import { FaTrophy } from "react-icons/fa";
import Addsuccessmodaldivident from '../modals/addsuccessmodaldivident';



import bnblogo from '../assests/images/bnb.png';
import bsclogo from '../assests/images/bsc.png';

import coinlogo from '../assests/images/logomini.png';

//modals
import ComingSoonmodal from '../modals/comingSoonmodal';

export default function Pendingrewards(props) {

    const [modalsucessdivident, setmodalsuccessdivident] = useState(false);
    const [modalcoming, setmodalcoming] = useState(false);


    return (
        <div className={`${cascading.approvecard} mb-4`}>

            <div className={`${cascading.innercard} ${cascading.dashcard}`}>
                <p className={`${cascading.head} ${cascading.headfelx}`}>
                    Pending Rewards
                    <div>
                        {/* <button className={`btn me-2 ${cascading.stakebtnicon}`} onClick={()=>{setmodalsuccessdivident(true);}}> */}
                        <button className={`btn me-2 ${cascading.stakebtnicon}`} onClick={() => {setmodalcoming(true)}}>

                            Claim All
                        </button>


                    </div>

                </p>



                <p className={`${cascading.headfelx} mt-4`}>
                    <div className='d-flex align-items-center'>
                        <div className={`${cascading.leftimgsec}`}>
                            <img src={bnblogo} alt='img' className={`img-fluid ${cascading.fromimg}`} />
                            <img src={coinlogo} alt='img' className={`img-fluid  ${cascading.toimg}`} />
                        </div>
                        <div>
                            <p className={`${cascading.coinnamewhitenew} mb-0`}>DSP-BNB</p>
                            <p className={`${cascading.coinnamewhitenew} mb-0`}>
                                0 <span className={`${cascading.coinnamegreynew}`}>($0.0001)</span>
                            </p>
                        </div>
                    </div>
                    {/* <button className={`btn me-2 ${cascading.stakebtnicon}`}>
                 Claim
                </button> */}
                    <button className={`btn me-2 ${cascading.stakebtnicon}`} onClick={() => {setmodalcoming(true)}}>
                        Claim
                    </button>

                </p>

                <p className={`${cascading.headfelx} mt-4`}>
                    <div className='d-flex align-items-center'>
                        <div className={`${cascading.tropyhyblack} ms-0`}>
                            <FaTrophy />
                        </div>
                        <div>
                            <p className={`${cascading.coinnamewhitenew} mb-0`}>YUM</p>
                            <p className={`${cascading.coinnamewhitenew} mb-0`}>
                                0 <span className={`${cascading.coinnamegreynew}`}>($0.0001)</span>
                            </p>
                        </div>
                    </div>
                    {/* <button className={`btn me-2 ${cascading.stakebtnicon}`}>
                 Claim
                </button> */}
                    <button className={`btn me-2 ${cascading.stakebtnicon}`} onClick={() => {setmodalcoming(true)}}>
                        Claim
                    </button>

                </p>

                <p className={`${cascading.headfelx} mt-4`}>
                    <div className='d-flex align-items-center'>
                        <div className={`${cascading.leftimgsec}`}>
                            <img src={bnblogo} alt='img' className={`img-fluid ${cascading.fromimg}`} />
                            <img src={bsclogo} alt='img' className={`img-fluid  ${cascading.toimg}`} />
                        </div>
                        <div>
                            <p className={`${cascading.coinnamewhitenew} mb-0`}>BUSD-BNB</p>
                            <p className={`${cascading.coinnamewhitenew} mb-0`}>
                                0 <span className={`${cascading.coinnamegreynew}`}>($0.0001)</span>
                            </p>
                        </div>
                    </div>
                    {/* <button className={`btn me-2 ${cascading.stakebtnicon}`}>
                 Claim
                </button> */}
                    <button className={`btn me-2 ${cascading.stakebtnicon}`} onClick={() => {setmodalcoming(true)}}>
                        Claim
                    </button>
                </p>






            </div>




            {modalsucessdivident && <Addsuccessmodaldivident onHide={() => setmodalsuccessdivident(false)} />}
            {modalcoming && <ComingSoonmodal show={modalcoming} onHide={() => { setmodalcoming(false); }} />}

        </div>
    )
}
