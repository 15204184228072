import React from 'react'
import cascading from '../assests/css/roundcardstyle.module.css';
import logo from '../assests/images/cardlogo.png'
import { FaArrowRightLong } from "react-icons/fa6";
import swapimg from '../assests/images/swapicon.png'
import dollarimg from '../assests/images/dollar.png'
import roundimg from '../assests/images/roundtable.png'
export default function Roundtablecard(props) {
    return (
        <div className={`${cascading.roundcard}`}>
            <div className={`${cascading.pfsec}`}>
                <div>
                    <img src={props.datas.pfimg} alt='' className={`img-fluid ${cascading.pfimg}`} />
                </div>
                <div>
                    <p className={`${cascading.tablename}`}>{props.datas.tablename}</p>
                    <div className={`${cascading.tabletype}`}>
                        {props.datas.type.map((e) => (
                            <p className={`${cascading.typename}`}>{e.names}</p>
                        ))}

                    </div>
                    <div className={`${cascading.valuemainrow}`}>
                        <div className={`${cascading.valuerow}`}>
                            <img src={dollarimg} alt='' className={`img-fluid ${cascading.iconimg}`} />
                            <p className={`${cascading.value}`}>$ {props.datas.pricevalue} <span>TVL</span></p>
                        </div>
                        <div className={`${cascading.valuerow}`}>
                            <img src={swapimg} alt='' className={`img-fluid ${cascading.iconimg}`} />
                            <p className={`${cascading.value}`}>$ {props.datas.swapvalue} <span>24h Volume</span></p>
                        </div>
                    </div>
                </div>

            </div>
            <div className={`${cascading.btnside}`}>
                <button className={`btn ${cascading.btnstyle}`}> View <FaArrowRightLong /> </button>
                
                <div className={`${cascading.userlistsec}`}>
                    {props.datas.userimg.map((e)=>(
                        <img src={e.img} alt='img' className={`img-fluid ${cascading.joinuserimg}`} />
                    ))}
                    
                </div>

            </div>


        </div>
    )
}
