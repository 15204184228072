import React, { useState } from 'react';
import cascading from '../assests/css/all.module.css';
import Footer from '../common/footer';
import pfimg from '../assests/images/roundtable.png';
import productimg from '../assests/images/gmbl.png'
import { Link } from 'react-router-dom';
import { FaExternalLinkAlt } from "react-icons/fa";
import { IoIosInformationCircleOutline } from "react-icons/io"
import Claimcard from '../components/claimcard';
import Header from '../common/header';
export default function Launchpaddetail() {
    const [stagedetails, setStagedetails] = useState([{
        img: require('../assests/images/roundtable.png'),
        names: "How price is determined",
        desc: [{
            para: 'You can contribute with USDC.e in exchange for GMBL and xGMBL tokens, which are to be claimed a few days after the sale (exact date TBA).'
        },
        {
            para: '3,250,000 GMBL and 1,750,000 xGMBL (out of a 100,000,000 total supply) will be allocated to the presale.'
        },
        {
            para: 'Price is fixed to $0.15.'
        }]
    },
    {
        img: require('../assests/images/roundtable.png'),
        names: "Three-stage sale",
        desc: [{
            para: 'This presale will start on August 27th @ 12pm UTC, and will take place in three different stages:'
        },
        {
            para: 'STAGE 1: During the first 12h, only whitelisted addresses can participate with a guaranteed capped allocation.'
        },
        {
            para: 'STAGE 2: During the following 12h, only whitelisted addresses can participate, with a 5x higher allocation cap.'
        },
        {
            para: 'STAGE 3: Starting August 28th @ 12pm UTC, other participants can purchase the remaining tokens on a first-come, first-served basis. This stage will last for 24 hours'
        }]
    },
    {
        img: require('../assests/images/roundtable.png'),
        names: "Claiming process",
        desc: [{
            para: 'The public sale will last until August 29th @ 12pm UTC'
        },
        {
            para: 'Shortly after, a LBP will take place on the Swerve platform.'
        },
        {
            para: 'Tokens from the presale and LBP participants will be claimable at the same time liquidity is seeded, shortly after the end of the LBP.'
        },
        {
            para: 'In order to align the long-term objectives of the protocol, the sale will be distributed in 65% of GMBL and 35% of xGMBL.'
        }]
    }])
    return (
        <>
         <Header/>

            <div className={`${cascading.innerpages} ${cascading.launchpaddetail}`}>
                <div className={`container`}>
                    <div className={`row ${cascading.toprow}`}>
                        <p className={`${cascading.breadcrum}`}>Launchpad &#8250; <span> GMBL.computer</span> </p>
                        <div className={`col-lg-8`}>
                            <div className={`${cascading.pfsec}`}>
                                <img src={pfimg} alt='img' className={`img-fluid ${cascading.pfimg}`} />
                                <p className={`${cascading.name}`}>GMBL.computer</p>
                            </div>
                            <p className={`${cascading.desc} mt-3`}>
                                The GMBL token is the native token of gmbl.computer, the first decentralized casino where all casino earnings go to users who stake the token, xGMBL.
                                The GMBL roadmap includes more games, a sportsbook, and slots - aiming to offer all the games traditional casinos offer, while sharing all profits with
                                stakers of $xGMBL.
                            </p>
                            <div>
                                <Link to="/" className={`${cascading.extlink}`}><FaExternalLinkAlt fill='#fff' />  Visit xGMBL</Link>
                            </div>
                        </div>
                        <div className={`offset-lg-1 col-lg-3 text-center`}>
                            <img src={productimg} alt='img' className={`img-fluid ${cascading.productimg}`} />
                        </div>


                    </div>
                    <div className={` ${cascading.inforow} mt-4 mx-0`}>
                        <div className={` ${cascading.svgsec}`}>
                            <IoIosInformationCircleOutline fill='#FE80C9' />
                        </div>
                        <div>
                            <p className={`${cascading.infodesc}`}> Please ensure you understand the public sale mechanics and terms before proceeding, deposited amounts CANNOT be withdrawn. The token will be sold for a fully diluted valuation (FDV) of 15M, fixing a $0.15 price per GMBL. Your allocation will
                                be made up of 65% GMBL and 35% xGMBL, its escrowed counterpart.</p>
                        </div>

                    </div>
                    <div className={`row ${cascading.statusrow} mt-4 `}>
                        <div className={`col-lg-3 mb-3`}>
                            <div className={`${cascading.statusline}`}></div>
                            <p className={`${cascading.statuslabel} `}>Whitelist stage</p>
                        </div>
                        <div className={`col-lg-3 mb-3`}>
                            <div className={`${cascading.statusline}`}></div>
                            <p className={`${cascading.statuslabel}`}>Public stage</p>
                        </div>
                        <div className={`col-lg-3 mb-3`}>
                            <div className={`${cascading.statusline}`}></div>
                            <p className={`${cascading.statuslabel}`}>End</p>
                        </div>
                        <div className={`col-lg-3 mb-3`}>
                            <div className={`${cascading.statusline} ${cascading.active}`}></div>
                            <p className={`${cascading.statuslabel} `}>Claims</p>
                        </div>

                    </div>
                    <div className={`row ${cascading.pricesec} mt-4`}>
                        <div className={`col-lg-4 ${cascading.leftsec}`}>
                            <p className={`${cascading.statusname}`}>Ended</p>
                            <p className={`${cascading.name}`}>Contribution are now claimble</p>
                            <div className={`${cascading.valuerows}`}>
                                <div className={`row ${cascading.valueinner}`}>
                                    <div className={`col-8`}>
                                        <p className={`${cascading.value}`}>750k/ 750k USDC.e</p>
                                        <p className={`${cascading.labelvalue}`}>Total raised / Hardcap</p>
                                    </div>
                                    <div className={`col-4`}>
                                        <p className={`${cascading.value}`}>$0.15</p>
                                        <p className={`${cascading.labelvalue}`}>GMBL price</p>
                                    </div>
                                </div>
                                <div className={`row ${cascading.valueinner}`}>
                                    <div className={`col-8`}>
                                        <p className={`${cascading.value}`}>$4.77M</p>
                                        <p className={`${cascading.labelvalue}`}>Circ. marketcap</p>
                                    </div>
                                    <div className={`col-4`}>
                                        <p className={`${cascading.value}`}>$15M</p>
                                        <p className={`${cascading.labelvalue}`}>FDV</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className={`offset-lg-1 col-lg-7`}>
                            <Claimcard/>

                        </div>

                    </div>
                    <div className={`${cascading.stagesec} mt-4`}>
                        <div className={`${cascading.toprow}`}>
                            <p className={`${cascading.label}`}>Info</p>
                            <p className={`${cascading.labeldesc}`}>Understanding the Sale Process</p>
                        </div>
                        <div className={`row`}>
                            {stagedetails.map((e) => (
                                <div className={`col-md-6 col-lg-4`}>
                                    <div className={`${cascading.stagedetail}`}>
                                        <img src={e.img} alt='img' className={`img-fluid ${cascading.stageimgs}`} />
                                        <p className={`${cascading.stagename}`}>{e.names}</p>
                                        {e.desc.map((e) => (
                                            <p className={`${cascading.stagedesc}`} >{e.para}</p>
                                        ))}



                                    </div>

                                </div>
                            ))}


                        </div>

                    </div>
                    <div className={` ${cascading.inforow} ${cascading.warning} mt-4 mx-0`}>
                        <div className={` ${cascading.svgsec}`}>
                            <IoIosInformationCircleOutline fill='#FD2B2B' />
                        </div>
                        <div>
                            <p className={`${cascading.infodesc}`}> DonutSwap is a suite of decentralized contracts built to support Arbitrum native builders. As a permissionless protocol, DonutSwap bears no responsibility for any tokens purchased using its contracts. All users are taking full responsibility that they are aware of the
                                relevant risks involved, and that they are participating for a token that is completely independent and not associated with DonutSwap in any way. Social media posts and visible information on the DonutSwap app in no way counts as an endorsement of a protocol by
                                the DonutSwap team, and nothing posted or shared in any DonutSwap media is a recommendation or financial advice.</p>
                        </div>

                    </div>





                </div>


            </div>



            <Footer />
        </>
    )
}
