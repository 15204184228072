import React, { useState } from 'react';
import { IoMdSettings } from "react-icons/io";
import { MdHistory } from "react-icons/md";
import toast, { Toaster } from 'react-hot-toast';

import cascading from '../../assests/css/createposition.module.css';
import Footer from '../../common/footer'
import { FaArrowDown, FaPlus } from "react-icons/fa";
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import { FaSyncAlt } from "react-icons/fa";


import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Accordion from 'react-bootstrap/Accordion';

import { FaRegQuestionCircle, FaArrowRight, FaChevronLeft, FaCopy } from "react-icons/fa";
import Header from '../../common/header';

import CopyToClipboard from "react-copy-to-clipboard";


import { useNavigate } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";
import bnbimg from '../../assests/images/bnb.png'
import Walletmodal from '../../modals/walletmodal';
import { useSelector } from 'react-redux';
import Recenthistory from '../../modals/recenthistory';
import Selecttoken from '../../modals/selecttoken';
import Settingsmodal from '../../modals/settingsmodal';
import ConfirmSwapmodal from '../../modals/confirmSwapmodal';
import Addliquiditymodal from '../../modals/addliquiditymodal';

import Addsuccessmodal from '../../modals/addsuccessmodal';
import { isEmpty } from '../../lib/isEmpty';
import { getWalletAddress } from '../../lib/localStorage';




export default function Createposition() {
  const navigate = useNavigate()
  const [swapsec, setSwapsec] = useState(true)
  const [walletmodalshow, setWalletmodalshow] = useState(false)
  const [recentshow, setRecentshow] = useState(false);
  const [tokenshow, setTokenshow] = useState(false);
  const [settingshow, setSettingshow] = useState(false)
  const [swapmodal, setSwapmodal] = useState(false)
  const [liquiditymodal, setLiquiditymodal] = useState(false)
  const [modalsucess, setmodalsuccess] = useState(false);


  const userdetail = useSelector((state) => state.admin)
  return (
    <>
      <Header />
      <div className={`${cascading.innerpages} ${cascading.swappage}`}>
        <div className={`container`}>
          <div className={`row justify-content-center ${cascading.swapcardrow}`}>
            <div className={`col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 `}>
              <div className={` ${cascading.cardstyle}`}>
                <div className={`${cascading.toprow}`}>
                  <div>
                    <p className={`${cascading.headings}`}>Add Liquidity</p>
                    <p className={`${cascading.desc}`}>Stable</p>
                  </div>
                  <div className={`${cascading.iconsec}`}>
                    <IoMdSettings onClick={() => setSettingshow(true)} />
                    <MdHistory onClick={() => setRecentshow(true)} />
                  </div>
                </div>
                <div className={`${cascading.inputsec} mb-4`}>
                  <div className={`${cascading.balancerow}`}>
                    <p className={`${cascading.inputlabel}`}>From</p>
                    {userdetail?.walletStatus == 'connect' &&!isEmpty(getWalletAddress()) && <p className={`${cascading.inputlabel}`}>Balance : 0.2155</p>}
                  </div>

                  <div className={`${cascading.inputsecrow}`}>
                    <div >
                      <input type="email" className={`from-control`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="0.0" />
                    </div>
                    {swapsec ?
                      <div className={` ${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}>
                        {userdetail?.walletStatus == 'connect' && !isEmpty(getWalletAddress()) && 
                        <button className={`btn ${cascading.currencybtn}`}> Max </button>}
                        <button className={`btn ${cascading.currencybtn}`} onClick={() => setTokenshow(true)}><img src={bnbimg} alt='bnb' className={`img-fluid ${cascading.coinimg}`} /> BNB <FaAngleDown fill='#fff' /></button>
                      </div>
                      :
                      <div className={` ${cascading.rightbtnsec}`}>
                        <button className={`btn ${cascading.currencybtn}`} onClick={() => setTokenshow(true)}>
                          {/* <img src={bnbimg} alt='bnb'className={`img-fluid ${cascading.coinimg}`}/>  */}
                          Select a Currency <FaAngleDown fill='#fff' /></button>
                      </div>

                    }


                  </div>

                </div>
                <div className={`row ${cascading.arrowrow}`} onClick={() => setSwapsec(!swapsec)}>
                  <div className={`${cascading.swaparrow}`}>
                    <FaPlus />
                  </div>
                </div>
                <div className={`${cascading.inputsec} mb-4`}>
                  <div className={`${cascading.balancerow}`}>
                    <p className={`${cascading.inputlabel}`}>To</p>
                    {userdetail?.walletStatus == 'connect' && !isEmpty(getWalletAddress()) && <p className={`${cascading.inputlabel}`}>Balance : 0</p>}
                  </div>

                  <div className={`${cascading.inputsecrow}`}>
                    <div >
                      <input type="email" className={`from-control`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="0.0" />
                    </div>
                    {!swapsec ?
                      <div className={` ${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}>
                        {userdetail?.walletStatus == 'connect' && !isEmpty(getWalletAddress()) && 
                        <button className={`btn ${cascading.currencybtn}`}> Max </button>
                        }
                        <button className={`btn ${cascading.currencybtn}`} onClick={() => setTokenshow(true)}><img src={bnbimg} alt='bnb' className={`img-fluid ${cascading.coinimg}`} /> BNB <FaAngleDown fill='#fff' /></button>
                        
                      </div>
                      :
                      <div className={` ${cascading.rightbtnsec}`}>
                        <button className={`btn ${cascading.currencybtn}`} onClick={() => setTokenshow(true)}>
                          {/* <img src={bnbimg} alt='bnb'className={`img-fluid ${cascading.coinimg}`}/>  */}
                          Select a Currency <FaAngleDown fill='#fff' /></button>
                      </div>

                    }

                  </div>

                </div>
                <div className={`${cascading.createaccordion} mb-4`}>

                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey={0}>
                      <Accordion.Header>
                        <div className={`mb-0 text-start pt-1 ${cascading.labelflex}`}>
                          <p className={`mb-3 text-start ${cascading.label}`}>
                            1 DSP = 0.00089 ETH
                            <span className='ps-1'>($2.87)</span></p>
                          <p className={`mb-0 text-start ${cascading.label}`}>1 ETH = 1,311.089786789 DSP
                            <span className='ps-1'>($3,251.56)</span></p>

                        </div>
                      </Accordion.Header>

                      <Accordion.Body className={`pb-1`}>
                        <div className={``}>
                          <div className={`${cascading.valuecardsec} ${cascading.valuecardsecnpng} mb-4`}>
                            <div className={`${cascading.secrow}`}>
                              <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>Pair Type

                              </p>
                              <p className={`mb-0 ${cascading.labels}`}>Volatile
                              </p>
                            </div>

                            <div className={`${cascading.secrow} mt-3`}>
                              <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>DSP Swap Rate

                              </p>
                              <p className={`mb-0 ${cascading.labels}`}>1 DSP <FaArrowRight /> 0.0000865 ETH
                              </p>
                            </div>

                            <div className={`${cascading.secrow} mt-3`}>
                              <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>ETH Swap Rate

                              </p>
                              <p className={`mb-0 ${cascading.labels}`}>1 ETH <FaArrowRight /> 0.0000865 DSP
                              </p>
                            </div>

                            <div className={`${cascading.secrow} mt-3`}>
                              <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>DSP/ETH liquidity ratio

                              </p>
                              <p className={`mb-0 ${cascading.labels}`}>0.0000824
                              </p>
                            </div>

                            <div className={`${cascading.secrow} mt-3`}>
                              <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>ETH/DSP liquidity ratio

                              </p>
                              <p className={`mb-0 ${cascading.labels}`}>1,131.0000824
                              </p>
                            </div>

                            <div className={`${cascading.secrow} mt-3`}>
                              <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>Pool Share

                              </p>
                              <p className={`mb-0 ${cascading.labels}`}><FaChevronLeft />0.001 %
                              </p>
                            </div>

                            <div className={`${cascading.secrow} mt-3`}>
                              <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>LP Address

                              </p>
                              <div className='d-flex align-items-center justify-content-between'>
                              <p className={`mb-0 ${cascading.labels}`}>0xBFCa...7B2Be3
                              </p>

                              <CopyToClipboard
                        text={"4JWktLr39ZbyutVnym7wKxV1RDoJz7ifDYxMUHDmAbPB"}
                        onCopy={() =>
                        toast('Address copied', {
                            duration: 2000,
                            position: 'top-right',
                          
                            // Styling
                            style: {background:"#1c2029", color:"#fff", minWidth:200},
                            className: '',
                          
                            // Custom Icon
                          
                            // Change colors of success/error/loading icon
                            iconTheme: {
                              primary: '#000',
                              secondary: '#fff',
                            },
                          
                            // Aria
                            ariaProps: {
                              role: 'status',
                              'aria-live': 'polite',
                            },
                          })
                        }
                        
                      > 
                      <button className={`mb-0 ${cascading.copybtntrans}`}><FaCopy /></button>
                      </CopyToClipboard>
                      </div>
                      

                            </div>

                            <div className={`${cascading.secrow} mt-3`}>
                              <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>NFT Pool Address

                              </p>
                              <div className='d-flex align-items-center justify-content-between'>
                              <p className={`mb-0 ${cascading.labels}`}>0xBFCa...7B2Be3
                              </p>

                              <CopyToClipboard
                        text={"4JWktLr39ZbyutVnym7wKxV1RDoJz7ifDYxMUHDmAbPB"}
                        onCopy={() =>
                        toast('Address copied', {
                            duration: 2000,
                            position: 'top-right',
                          
                            // Styling
                            style: {background:"#1c2029", color:"#fff", minWidth:200},
                            className: '',
                          
                            // Custom Icon
                          
                            // Change colors of success/error/loading icon
                            iconTheme: {
                              primary: '#000',
                              secondary: '#fff',
                            },
                          
                            // Aria
                            ariaProps: {
                              role: 'status',
                              'aria-live': 'polite',
                            },
                          })
                        }
                        
                      > 
                      <button className={`mb-0 ${cascading.copybtntrans}`}><FaCopy /></button>
                      </CopyToClipboard>
                      </div>
                      

                            </div>


                          </div>

                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                  </Accordion>
                </div>

               
                <div className={`${cascading.btnsec}`}>
                  {userdetail?.walletStatus !== 'connect' &&  isEmpty(getWalletAddress()) ? 
                  <button className={`btn ${cascading.connect_btn}`} onClick={() => setWalletmodalshow(true)}>Connect Wallet</button> :
                    <button className={`btn ${cascading.connect_btn}`} onClick={()=>{setLiquiditymodal(true)}}>Create Position</button>}


                </div>
              </div>

              {/* <div className={`mt-3 pb-1 ${cascading.cardstyle}`}>
                <div className={`${cascading.valuecardsec} ${cascading.valuecardsecnpng} mb-4`}>
                  <div className={`${cascading.secrow}`}>
                    <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>Minimum Received
                      <OverlayTrigger
                        className={`${cascading.tooltip_style}`}
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-top`}>
                            Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.
                          </Tooltip>
                        }
                      >
                        <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
                      </OverlayTrigger>
                    </p>
                    <p className={`mb-0 ${cascading.labels}`}>0.00372 DB
                    </p>
                  </div>

                  <div className={`mt-3 ${cascading.secrow}`}>
                    <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>Price Impact
                      <OverlayTrigger
                        className={`${cascading.tooltip_style}`}
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-top`}>
                            The difference between the market price and estimated price due to trade size.
                          </Tooltip>
                        }
                      >
                        <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
                      </OverlayTrigger>
                    </p>
                    <p className={`mb-0 ${cascading.labels}`}>45.07%
                    </p>
                  </div>


                  <div className={`mt-3 ${cascading.secrow}`}>
                    <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>Liquidity Provider Fee
                      <OverlayTrigger
                        className={`${cascading.tooltip_style}`}
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-top`}>
                            <p className='mb-1'>For each trade a 0.25% fee is paid</p>
                            <p className='mb-1'>- 0.17% to LP token holders</p>
                            <p className='mb-1'>- 0.03% to the Treasury</p>
                            <p className='mb-1'>- 0.05% towards DSP buyback and burn</p>


                          </Tooltip>
                        }
                      >
                        <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
                      </OverlayTrigger>
                    </p>
                    <p className={`mb-0 ${cascading.labels}`}>0.00372124456 BNB
                    </p>
                  </div>
                </div>

              </div> */}
            </div>

          </div>


        </div>


      </div>



      <Footer />
      {walletmodalshow && <Walletmodal show={walletmodalshow} onHide={() => setWalletmodalshow(false)} />}
      {recentshow && <Recenthistory show={recentshow} onHide={() => setRecentshow(false)} />}
      {tokenshow && <Selecttoken show={tokenshow} onHide={() => setTokenshow(false)} />}
      {settingshow && <Settingsmodal show={settingshow} onHide={() => setSettingshow(false)} />}

      {swapmodal && <ConfirmSwapmodal show={swapmodal} onHide={() => setSwapmodal(false)} />} 
      {liquiditymodal && <Addliquiditymodal show={liquiditymodal} showsucces={()=>{setmodalsuccess(true);setLiquiditymodal(false);}} onHide={() => {setLiquiditymodal(false);}} />} 
      {modalsucess && <Addsuccessmodal show={modalsucess} onHide={() => {setmodalsuccess(false);}} />} 
      
      </>
  
  )
}
