import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/positionmodalstyle.module.css';
import toast, { Toaster } from 'react-hot-toast';
import Accordion from 'react-bootstrap/Accordion';
import CopyToClipboard from "react-copy-to-clipboard";
import { FaInfoCircle, FaChevronLeft, FaCopy, FaAngleUp, FaExternalLinkAlt, FaRegQuestionCircle, FaRegTimesCircle, FaDownload, FaUpload, FaLock, FaRocket, FaCoins, FaCheckCircle } from "react-icons/fa";


import { FaArrowDown } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import bnblogo from '../assests/images/bnb.png';
import coinlogo from '../assests/images/logomini.png';





import { IoMdClose } from "react-icons/io";
export default function Addpositionmodal(props) {

  const [addpositionmodal, setAddpositionmodal] = useState(true);
  const [isyielded, setIsyielded] = useState(true);
  const [islocked, setIslocked] = useState(true);
  const [isboosted, setIsboosted] = useState(false);









  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal centered show={addpositionmodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
          <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
            <h4 className={`${cascading.modaltitle}`}>Add Position</h4>
            <IoMdClose className={`${cascading.closebtn}`} onClick={() => { props.onHide(); props.onhideposition(); }} />

          </Modal.Header>
          <Modal.Body className={`pt-0 ${cascading.modalclr} ${cascading.modalbody}`}>

            <div>

              <div className={`${cascading.imgsec}`}>
                <div className={`${cascading.leftimgsec}`}>
                  <img src={bnblogo} alt='img' className={`img-fluid ${cascading.fromimg}`} />
                  <img src={coinlogo} alt='img' className={`img-fluid  ${cascading.toimg}`} />
                </div>
                <div>
                  <p className={`${cascading.coinname} mb-0`}>DSP-BNB</p>
                  <p className={`${cascading.coinnamegrey} mb-0`}>#ID-124</p>
                </div>


              </div>


              {/* <p className={`${cascading.tokenvalue}`}>$1.03 - <span>20.03%</span> APR</p> */}
              <p className={`${cascading.tokenvaluedesc} mt-3`}>Deposit more into this spNFT to increase your yield.</p>





              <p className={`${cascading.innerheadwhitetitle} mt-4`}>Amount
              </p>
              <p className={`${cascading.tokenvaluedesc} text-start`}>You need to own DSP-BNB LP tokens to directly add more liquidity to this position. If that's not the case, head to the liquidity page first.</p>


              <div className={`${cascading.inputsec} my-3`}>
                <div className={`input-group mb-3`}>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder="0"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                  <div className={`input-group-append`} >
                    <button className={` ${cascading.inputspan}`} id="basic-addon1">
                      Max
                    </button>
                  </div>
                </div>
                <p className={`${cascading.balsec}`}>balance : 0.00000000000672770063 DSP-BNB</p>
              </div>

              <p className={`${cascading.innerheadwhitetitle} mt-4`}>Estimates
              </p>









              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Deposit Value</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>$2.05</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Swap fees APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>6.38%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Farm base APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>1.02%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Lock bonus APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>0%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>7.41%</p>
                </div>
              </div>

              <div className={`${cascading.warning_border} mt-4`}>
                <div className='d-flex align-items-start justify-content-start'>
                  <FaInfoCircle className={`${cascading.themeicon}`} />
                  <span className={`${cascading.label}`}>By making a new deposit on this position, you will renew its lock for 4 days from now.</span>
                </div>

              </div>
            </div>





            <div className='mt-4'>


              <button className={`btn ${cascading.connectwalletbtn}`} type='button' onClick={() => { props.onHide(); props.onSuccess(); }}>Add Liquidity</button>
              <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button>

            </div>



            {/* <div className='p-3 text-center'>
<button className={`btn ${cascading.connectwalletbtn}`} type='button'>Close</button>

</div> */}







          </Modal.Body>

        </Modal>
      </div>
    </>
  )
}

