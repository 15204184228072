import React, { useState } from 'react';
import cascading from '../../assests/css/xgrailpools.module.css';
import Accordion from 'react-bootstrap/Accordion';
import Footer from '../../common/footer'
import Header from '../../common/header';
import { useNavigate } from 'react-router-dom';
import { FaThList } from "react-icons/fa";
import { BsFillGrid3X2GapFill } from "react-icons/bs";
import Switch from "react-switch";
import { Dropdown } from 'react-bootstrap';
import Farmcard from '../../components/farmcard';
import bnblogo from '../../assests/images/bnb.png';
import coinlogo from '../../assests/images/logomini.png';

import home_icon from '../../assests/images/home_icon.png';
import pools_icon from '../../assests/images/pools_icon.png';
import total_icon from '../../assests/images/totalalloc.png';
import time_icon from '../../assests/images/timer.png';



import logohome from '../../assests/images/coinicon.png';

import Positionlist from '../../components/positionlist';


//modals
import ComingSoonmodal from '../../modals/comingSoonmodal';
import Addliquiditymodal from '../../modals/addliquiditymodal';

import Addsuccessmodal from '../../modals/addsuccessmodal';

export default function Positions() {

    const [status, setStatus] = useState(true);
    const [liquiditymodal, setLiquiditymodal] = useState(false)
    const [modalsucess, setmodalsuccess] = useState(false);
    const [modalcoming, setmodalcoming] = useState(false);


    return (
        <>
            <Header />
            <div className={`${cascading.innerpages} ${cascading.earnpage}`}>
                <div className={`container`}>
                    <div className={`row ${cascading.headrow}`}>
                        <p className={`${cascading.pagehead}`}>Positions</p>
                        <p className={`${cascading.pagedesc}`}>Create and manage all your staking positions.</p>
                    </div>
                    <div className='row'>
                        <div className={`col-lg-3 mb-4`} >

                            <div className={`${cascading.balancecard}`}>
                                <div>
                                    <p className={`${cascading.labelname}`}>Positions</p>
                                    <p className={`${cascading.labelvalue}`}>0</p>
                                </div>
                                <div>
                                    <img className={`img-fluid ${cascading.balanceimg}`} src={home_icon} alt='img' />
                                </div>
                            </div>
                        </div>

                        <div className={`col-lg-3 mb-4`} >

                            <div className={`${cascading.balancecard}`}>
                                <div>
                                    <p className={`${cascading.labelname}`}>Total holdings</p>
                                    <p className={`${cascading.labelvalue}`}>0</p>
                                </div>
                                <div>
                                    <img className={`img-fluid ${cascading.balanceimg}`} src={total_icon} alt='img' />
                                </div>
                            </div>
                        </div>

                        <div className={`col-lg-3 mb-4`} >

                            <div className={`${cascading.balancecard}`}>
                                <div>
                                    <p className={`${cascading.labelname}`}>Pending rewards</p>
                                    <p className={`${cascading.labelvalue}`}>0</p>
                                </div>
                                <div>
                                    <img className={`img-fluid ${cascading.balanceimg}`} src={time_icon} alt='img' />
                                </div>
                            </div>
                        </div>
                        <div className={`col-lg-3 mb-4`} >
                            <div className={`${cascading.balancecard}`}>
                                <div>
                                    <p className={`${cascading.labelname}`}>SPNFTS</p>
                                    <p className={`${cascading.labelvalue}`}>$0</p>
                                </div>
                                <div>
                                    <img className={`img-fluid ${cascading.balanceimg}`} src={pools_icon} alt='img' />
                                </div>
                            </div>
                        </div>
                    </div>





                    <div className={`row ${cascading.filterrow}`}>
                        <div className={`col-lg-6 mb-3 mb-lg-0`}>
                            <div className={`${cascading.leftfiltersec}`}>

                            </div>

                        </div>
                        <div className={`col-lg-12 mb-3`}>
                            <div className={`${cascading.rightfiltersec}`}>
                                <div>
                                    <p className={`${cascading.labelname} mb-2`}>Filters</p>
                                    <Dropdown className={`${cascading.dropdownstyle}`}>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            Yield-bearing only
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className={`${cascading.dropdownmenustyle}`}>
                                            <Dropdown.Item href="#/action-1">Yield-bearing only</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Locked only</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Boosted only</Dropdown.Item>

                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                               
                                {/* <button className={`btn ${cascading.connect_btn} ms-auto`} type='button' onClick={() => { setLiquiditymodal(true) }}>New Position</button> */}
                                <button className={`btn ${cascading.connect_btn} ms-auto`} type='button'  onClick={() => { setmodalcoming(true) }}>New Position</button>



                                {/* <div>
                                    <p className={`${cascading.labelname} mb-2`}>Search</p>
                                    <input type="text" className={`form-control ${cascading.inputstyle}`} placeholder="Search" aria-label="Username" aria-describedby="basic-addon1" />


                                </div> */}

                            </div>
                        </div>

                    </div>

                    <div className={`${cascading.farmcontentsec} mt-4`}>


                        {status ?
                            <>
                                <div className={`row`}>
                                    <Positionlist />

                                </div>

                            </>
                            :
                            <>
                                <p className={`${cascading.emptylabels}`}>These pools are no longer distributing rewards. Please unstake your tokens.</p>


                            </>
                        }


                    </div>
                </div>


            </div>



            <Footer />

            {liquiditymodal && <Addliquiditymodal show={liquiditymodal} onHide={() => { setLiquiditymodal(false) }} onSuccess={() => { setmodalsuccess(true); }} />}
            {modalsucess && <Addsuccessmodal show={modalsucess} onHide={() => { setmodalsuccess(false); }} />}
            {modalcoming && <ComingSoonmodal show={modalcoming} onHide={() => { setmodalcoming(false); }} />}

        </>
    )
}
