import React, { useState, useEffect } from 'react'
import cascading from '../assests/css/all.module.css';
import { Button, ButtonGroup } from 'react-bootstrap';
import { BiRightArrowAlt } from "react-icons/bi";
import { FaAngleLeft } from "react-icons/fa";

//lib
import { Duration } from '../lib/DateTimeHelper';

//hooks
import { GetRedeemLength, GetRedeemInfo } from '../hooks/useXtoken';
import { getWalletAddress } from '../lib/localStorage';


export default function Vestingcard(props) {

    const [redeemDetails, setRedeemDetails] = useState([])
    useEffect(() => {
        RedeemDetails()
    }, [])
    const RedeemDetails = async () => {
        try {
            let account = getWalletAddress()
            let RedeemLength = await GetRedeemLength(account)
            let RedeemDetails = []
            console.log(RedeemLength, 'RedeemLegth')
            for (let i = 0; i < RedeemLength; i++) {
                let RedeemDetail = await GetRedeemInfo(account, i)
                RedeemDetails.push(RedeemDetail)
                if (i == RedeemLength - 1) {
                    setRedeemDetails(RedeemDetails)
                }
                console.log(RedeemDetails, 'RedeemDetails', RedeemDetail)
            }
        } catch (err) {
            console.log(err, "RedeemDetails__err")
        }
    }
    return (
        <div className={`mt-3 mb-4 ${cascading.approvecard}`}>
            <div className={`container`}>
                <div className={`row pt-4 ${cascading.headrow}`}>
                    <p className={`${cascading.pagehead}`}>Vesting</p>
                    <p className={`text-uppercase ${cascading.pagedesc}`}>Pending</p>
                </div>
                {
                    redeemDetails.length > 0 ? redeemDetails.map((val, i) => {
                        return (
                            <div className='row align-items-center pb-4'>
                                <div className='col-12 col-sm-3 col-md-3 text-center text-sm-start pe-0'>
                                    <p className={`mb-0 ${cascading.textwhitexgrail}`}>{val.grailAmount/10**18} DSP</p>
                                </div>
                                <div className='col-12 col-sm-1 col-md-1 text-center text-sm-start ps-1 pe-0'>
                                    <BiRightArrowAlt fill='#fff' />
                                </div>
                                <div className='col-12 col-sm-4 col-md-4 text-sm-start text-center ps-1 pe-0'>
                                    <p className={`mb-0 ${cascading.textwhitexgrail}`}>44d 23h 59mins</p>
                                </div>
                                <div className='col-12 col-md-1 col-sm-1 text-center text-sm-start ps-1 pe-0'>
                                    <BiRightArrowAlt fill='#fff' />
                                </div>
                                <div className='col-12 col-md-3 col-sm-3 text-center text-sm-start ps-1 pe-0'>
                                    <FaAngleLeft fill='#FE80C9' /><span className={`mb-0 ${cascading.textthemexgrail}`}>{val.xGrailAmount/10**18} YUM</span>
                                </div>
                            </div>
                        )
                    }) : ''
                }

            </div>
        </div>
    )
}
