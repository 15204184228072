import React, { useState } from 'react'
import cascading from '../assests/css/all.module.css';
import { Button, ButtonGroup } from 'react-bootstrap';
import { BiRightArrowAlt } from "react-icons/bi";
import { FaMinus, FaPlus } from "react-icons/fa6";

//modals
import ComingSoonmodal from '../modals/comingSoonmodal';
import Allocatemodal from '../modals/allocatemodal';
import Deallocatemodal from '../modals/deallocatemodal';

export default function Yourallocation(props) {

    const [allocatemodal, setAllocatemodal] = useState(false);
    const [deallocatemodal, setDeallocatemodal] = useState(false);
    const [modalcoming, setmodalcoming] = useState(false);


    return (
        <div className={`${cascading.approvecard} mb-4`}>

            <div className={`${cascading.innercard} ${cascading.dashcard}`}>
                <p className={`${cascading.head} ${cascading.headfelx}`}>
                    Your Allocation
                    <div>

                        {/* <button className={`btn me-2 ${cascading.stakebtnicon}`} onClick={()=>{setDeallocatemodal(true);}}> */}
                        <button className={`btn me-2 ${cascading.stakebtnicon}`} onClick={() => { setmodalcoming(true) }}>
                            <FaMinus />
                        </button>
                        {/* <button className={`btn ${cascading.stakebtnicon}`} onClick={()=>{setAllocatemodal(true);}}> */}
                        <button className={`btn ${cascading.stakebtnicon}`} onClick={() => { setmodalcoming(true) }}>
                            <FaPlus />
                        </button>

                    </div>

                </p>
                <p className={`${cascading.desc}`}>
                    Earn real yield weekly from protocol earnings with YUM.
                </p>

                <div className={`${cascading.innercard} ${cascading.innercardalloc} mt-3`}>
                    <div className='mt-2'>
                        <p className={`${cascading.labelname}`}>Your allocation</p>
                        <p className={`${cascading.value}`}>0 YUM</p>
                    </div>
                    <div className='mt-2'>
                        <p className={`${cascading.labelname}`}>Manual allocations</p>
                        <p className={`${cascading.value}`}>0 YUM</p>
                    </div>
                    <div className='mt-2'>
                        <p className={`${cascading.labelname}`}>Total share</p>
                        <p className={`${cascading.value}`}>0 %</p>
                    </div>
                    <div className='mt-2'>
                        <p className={`${cascading.labelname}`}>Redeem allocation</p>
                        <p className={`${cascading.value}`}>0 YUM</p>
                    </div>
                </div>

                {/* <div className='text-center mt-3'>
          <button className={`btn ${cascading.stakebtnicon}`}>
                 Allocate
                </button>
          </div> */}



            </div>

            {allocatemodal && <Allocatemodal onHide={() => setAllocatemodal(false)} />}
            {deallocatemodal && <Deallocatemodal onHide={() => setDeallocatemodal(false)} />}
            {modalcoming && <ComingSoonmodal show={modalcoming} onHide={() => { setmodalcoming(false); }} />}



        </div>
    )
}
