import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/addliquiditystyle.module.css';
import toast, { Toaster } from 'react-hot-toast';
import Accordion from 'react-bootstrap/Accordion';
import CopyToClipboard from "react-copy-to-clipboard";
import { FaRegQuestionCircle, FaArrowRight, FaChevronLeft, FaCopy } from "react-icons/fa";


import { FaArrowDown } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import bnblogo from '../assests/images/bnb.png';
import coinlogo from '../assests/images/logomini.png';




import { IoMdClose } from "react-icons/io";
export default function Lockmodal(props) {

  const [lockmodal, setLockmodal] = useState(true);







  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal centered show={lockmodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
          <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
            <h4 className={`${cascading.modaltitle}`}>Lock Position</h4>
            <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

          </Modal.Header>
          <Modal.Body className={`pt-0 ${cascading.modalclr} ${cascading.modalbody}`}>

            <div>

              <div className={`${cascading.imgsec}`}>
                <div className={`${cascading.leftimgsec}`}>
                  <img src={bnblogo} alt='img' className={`img-fluid ${cascading.fromimg}`} />
                  <img src={coinlogo} alt='img' className={`img-fluid  ${cascading.toimg}`} />
                </div>
                <div>
                  <p className={`${cascading.coinname} mb-0`}>DSP-BNB</p>
                  <p className={`${cascading.coinnamegrey} mb-0`}>#ID-124</p>
                </div>


              </div>


              {/* <p className={`${cascading.tokenvalue}`}>$1.03 - <span>20.03%</span> APR</p> */}
              <p className={`${cascading.tokenvaluedesc} mt-3`}>Provide long-term liquidity to increase your yield</p>





              <p className={`${cascading.innerheadwhitetitle} mt-4`}>Settings
              </p>



              <div className='row mt-4'>
                <div className='col-12 col-md-5'>
                  <p className={`mb-0 ${cascading.innerheadwhite}`}>Lock duration
                  </p>
                  <a href="javascript:void(0)" className={`${cascading.themelink}`}>Set Max</a>
                </div>
                <div className='col-12 col-md-7 mt-3 mt-md-0'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <button className={`btn ${cascading.themelink} ${cascading.buttonstyle_ul}`}>
                      <FaMinus />
                    </button>
                    <div>
                      <p className={`mb-1 text-center ${cascading.textgreydesc}`}>Months</p>
                      <p className={`mb-0 text-center ${cascading.textwhitedesc}`} >0</p>


                    </div>
                    <div>
                      <p className={`mb-1 text-center ${cascading.textgreydesc}`}>Days</p>
                      <p className={`mb-0 text-center ${cascading.textwhitedesc}`} >0</p>


                    </div>
                    <button className={`btn ${cascading.themelink} ${cascading.buttonstyle_ul}`}>
                      <FaPlus />
                    </button>
                  </div>
                  <p className={`mb-1 text-end mt-3 ${cascading.textgreydesc}`}>1.64% lock bonus (x1.02)</p>
                </div>
              </div>




              <p className={`${cascading.innerheadwhitetitle} mt-4`}>Estimates
              </p>


              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Deposit value</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>$2.04</p>
                </div>
              </div>



              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Swap fees APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>6.38%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Farm base APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>1.02%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Lock bonus APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>0%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>7.41%</p>
                </div>
              </div>


            </div>





            <div className='mt-4'>


              <button className={`btn ${cascading.connectwalletbtn}`} type='button' onClick={() => { props.onSuccess(); props.onHide() }}>Lock</button>
              <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button>

            </div>



            {/* <div className='p-3 text-center'>
<button className={`btn ${cascading.connectwalletbtn}`} type='button'>Close</button>

</div> */}







          </Modal.Body>

        </Modal>
      </div>
    </>
  )
}

