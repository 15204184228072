import React, { useState } from 'react';
import cascading from '../assests/css/all.module.css';
import Footer from '../common/footer';
import ReactDatatable from '@ashvin27/react-datatable';
import { FaArrowLeftLong } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import pfimg from '../assests/images/tabledetailimg.png'
import { FaTelegramPlane } from 'react-icons/fa';
import bnb from '../assests/images/bnbwithlogo.png';
import { IoMdMail } from "react-icons/io";
import { FaFile } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import Header from '../common/header';
export default function Tabledetail() {
    const config = {
        button: {
            excel: false,
            print: false,
            extra: false,
        },
        show_filter: false,
        show_length_menu: false,
        show_pagination: false
    }

    const columns = [
        {
            key: "pool",
            text: "Pool",
            className: "pool",
            align: "left",
            sortable: true,
            cell: record => {
                return (
                    <>
                        <div className={`${cascading.detailtablepools}`}>
                            <img src={bnb} alt='img' className={`img-fluid`} />
                            {record.pool}
                            <span>V2</span>
                        </div>

                    </>
                );
            }


        },
        {
            key: "tvl",
            text: "TVL",
            className: "tvl",
            align: "left",
            sortable: true
        },
        {
            key: "apr",
            text: "APR range",
            className: "apr",
            sortable: true
        },
        {
            key: "deposit",
            text: "Your deposits",
            className: "deposit",
            align: "left",
            sortable: true
        },
        {
            key: "rewards",
            text: "Pending rewards",
            className: "rewards",
            sortable: true,
            align: "left"
        },

    ];

    const records = [
        {
            "pool": "DST-BNB.e",
            "tvl": "165.4 k",
            "apr": "5.49 % > 5.49",
            "deposit": "0",
            "rewards": "0"
        },
        {
            "pool": "DST-BNB.e",
            "tvl": "165.4 k",
            "apr": "5.49 % > 5.49",
            "deposit": "0",
            "rewards": "0"
        },
        {
            "pool": "DST-BNB.e",
            "tvl": "165.4 k",
            "apr": "5.49 % > 5.49",
            "deposit": "0",
            "rewards": "0"
        },
        {
            "pool": "DST-BNB.e",
            "tvl": "165.4 k",
            "apr": "5.49 % > 5.49",
            "deposit": "0",
            "rewards": "0"
        },
        {
            "pool": "DST-BNB.e",
            "tvl": "165.4 k",
            "apr": "5.49 % > 5.49",
            "deposit": "0",
            "rewards": "0"
        },
        {
            "pool": "DST-BNB.e",
            "tvl": "165.4 k",
            "apr": "5.49 % > 5.49",
            "deposit": "0",
            "rewards": "0"
        },
        {
            "pool": "DST-BNB.e",
            "tvl": "165.4 k",
            "apr": "5.49 % > 5.49",
            "deposit": "0",
            "rewards": "0"
        },


    ];
    const columns1 = [
        {
            key: "token",
            text: "Token",
            className: "token",
            align: "left",
            sortable: true,
            cell: record => {
                return (
                    <>
                        <div className={`${cascading.detailtablepools}`}>
                            <img src={bnb} alt='img' className={`img-fluid`} />
                            {record.token}
                            <span>V2</span>
                        </div>

                    </>
                );
            }

        },
        {
            key: "amount",
            text: "Amount",
            className: "amount",
            align: "left",
            sortable: true
        },
        {
            key: "composition",
            text: "Composition  ",
            className: "composition",
            sortable: true
        },
        {
            key: "fees",
            text: "Fees APR",
            className: "fees",
            align: "left",
            sortable: true
        },
        {
            key: "poolshare",
            text: "Pool share",
            className: "poolshare",
            sortable: true,
            align: "left"
        },

    ];

    const records1 = [


    ];
    return (
        <>
         <Header/>
            <div className={`${cascading.innerpages} ${cascading.tabledetailpage}`}>
                <div className={`container`}>
                    <div className={`row mx-0`}>
                        <div className={`${cascading.backbtn}`}>
                            <FaArrowLeftLong fill='#fff' /> Back to List
                        </div>
                        <div className={`${cascading.labelsec}`}>
                            <p className={`${cascading.labelname}`}>Round Table Project</p>
                            <Link to='' className={`${cascading.linkname}`}>VisitGMX website</Link>

                        </div>

                    </div>
                    <div className={`row mt-3 ${cascading.topdetailsec} mb-4`}>
                        <div className={`col-lg-6`}>
                            <p className={`${cascading.tablename}`}>Meet WINR PROTOCOL</p>
                            <p className={`${cascading.tabledesc}`}>GMX is a decentralized spot and perpetual exchange that supports low swap fees and zero
                                price impact trades. Trading is supported by a unique multi-asset pool that earns liquidity
                                providers fees from market making, swap fees and leverage trading. Dynamic pricing is
                                supported by Chainlink Oracles and an aggregate of prices from leading volume exchanges.</p>
                            <div className={`${cascading.socialsec}`}>
                                <p className={`${cascading.labelname}`}>FIND US</p>
                                <div className={`${cascading.sociallist}`}>
                                    <ul>
                                        <li>
                                            <Link to="" >  <RiTwitterXFill /> </Link>
                                        </li>
                                        <li>
                                            <Link to="" >  <FaTelegramPlane /> </Link>
                                        </li>
                                        <li>
                                            <Link to="" >  <FaFile /> </Link>
                                        </li>
                                        <li>
                                            <Link to="" > <IoMdMail /> </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className={`col-lg-6 ${cascading.imgsec}`}>
                            <img src={pfimg} alt='img' className={`img-fluid ${cascading.tableimg}`} />
                        </div>


                    </div>
                    <div className={`row ${cascading.pricerow}`}>
                        <div className={`col-lg-4`}>
                            <div className={`${cascading.typerow}`}>
                                <p className={`${cascading.typename}`}>Dex</p>
                                <p className={`${cascading.typename}`}>Leverage</p>
                                <p className={`${cascading.typename}`}>Perpetual</p>
                            </div>
                            <p className={`${cascading.roundname}`}>Decentralized Perpetual Exchange </p>
                            <div className={`${cascading.valuerow}`}>
                                <div className={`${cascading.valuefirstrow}`}>
                                    <div>
                                        <p className={`${cascading.value}`}>$ 4.89M</p>
                                        <p className={`${cascading.labelvalue}`}>Total liquidity</p>
                                    </div>
                                    <div>
                                        <p className={`${cascading.value}`}>$ 2.1M</p>
                                        <p className={`${cascading.labelvalue}`}>24h volume</p>
                                    </div>
                                </div>
                                <div>
                                    <p className={`${cascading.value}`}>$ 51.1M</p>
                                    <p className={`${cascading.labelvalue}`}>GMX price</p>
                                </div>
                            </div>

                        </div>
                        <div className={`col-lg-4 mb-4`}>
                            <div className={`${cascading.pageimgsec}`}>
                                <button className={`btn ${cascading.pagebtn}`} >Swap </button>
                            </div>
                        </div>
                        <div className={`col-lg-4 mb-4`}>
                            <div className={`${cascading.pageimgsec1}`}>
                                <button className={`btn ${cascading.pagebtn}`} >Liquidity </button>
                            </div>
                        </div>

                    </div>
                    <div className={`row mt-5 ${cascading.tablerow}`}>
                        <div className={`mt-3`}>
                            <div className={`col-12`}>
                                <p className={`${cascading.tablename} mx-0`}>Pools <span>V2</span></p>
                            </div>

                            <div className={`${cascading.tabstyle} table-responsive`} id='tablestyle1'>


                                <ReactDatatable
                                    // data={data}
                                    className={`${cascading.datatablestyle}`}
                                    config={config}
                                    columns={columns}
                                    records={records}

                                />
                            </div>
                        </div>
                        <div className={`mt-4`}>
                            <div className={`col-12`}>
                                <p className={`${cascading.tablename}`}>LP <span>V2</span></p>
                            </div>
                            <div className={`${cascading.tabstyle}  table-responsive`} id='tablestyle1'>


                                <ReactDatatable
                                    // data={data}
                                    className={`${cascading.datatablestyle}`}
                                    config={config}
                                    columns={columns1}
                                    records={records1}

                                />
                            </div>
                        </div>

                    </div>






                </div>


            </div>



            <Footer />
        </>
    )
}
