import React, { useState } from 'react'
import cascading from '../assests/css/all.module.css';
import logo from '../assests/images/cardlogo.png'
import Walletmodal from '../modals/walletmodal';
import { FaAngleDown } from "react-icons/fa6";
import { FaAngleUp, FaExternalLinkAlt, FaRegQuestionCircle, FaDownload, FaUpload, FaLock, FaRocket, FaCoins } from "react-icons/fa";
import { FaAngleLeft } from "react-icons/fa";

import { useSelector } from 'react-redux';
import pools_icon from '../assests/images/pools_icon.png';

import ReactDatatable from '@ashvin27/react-datatable';
import positionmodal from '../modals/positionmodal';


import { AiOutlineCalculator } from "react-icons/ai";
import { Link } from 'react-router-dom';
import Calculatormodal from '../modals/calculatormodal';
import { Accordion, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import bnblogo from '../assests/images/bnb.png';
import coinlogo from '../assests/images/logomini.png';
import { useNavigate } from 'react-router-dom';


//modals
import ComingSoonmodal from '../modals/comingSoonmodal';
import Positionmodal from '../modals/positionmodal';
import Addpositionmodal from '../modals/addpositionmodal';
import Addsuccessmodal from '../modals/addsuccessmodal';
import Harvestpositionmodal from '../modals/harvestpositionmodal';
import Lockpositionmodal from '../modals/lockpositionmodal';
import Boostpositionmodal from '../modals/boostpositionmodal';





export default function Positionlist(props) {
    const navigate = useNavigate();

    //   const [isbeared, setIsbeared] = useState(false);
    //   const [islocked, setIslocked] = useState(false);
    //   const [isboosted, setIsboosted] = useState(false);

    const [positionmodal, setPositionmodal] = useState(false);


    const [addpositionmodal, setAddpositionmodal] = useState(false);
    const [harvestpositionmodal, setHarvestpositionmodal] = useState(false);
    const [lockpositionmodal, setLockpositionmodal] = useState(false);
    const [boostpositionmodal, setBoostpositionmodal] = useState(false);
    const [modalcoming, setmodalcoming] = useState(false);


    const [modalsucess, setModalsucess] = useState(false);






    const config = {
        button: {
            excel: false,
            print: false,
            extra: false,
        },
        // show_filter:false,
        show_length_menu: false,
        show_pagination: false
    }

    const columns = [
        {
            key: "token",
            text: "Token",
            className: "token",
            align: "left",
            sortable: true,
            cell: record => {
                return (
                    <>
                        {/* <div className={`${cascading.imgsec} ${cascading.cursor_pointer_column}`} onClick={() => setPositionmodal(true)}> */}
                        <div className={`${cascading.imgsec} ${cascading.cursor_pointer_column}`} onClick={() => setmodalcoming(true)}>

                            <div className={`${cascading.leftimgsec}`}>
                                <img src={bnblogo} alt='img' className={`img-fluid ${cascading.fromimg}`} />
                                <img src={coinlogo} alt='img' className={`img-fluid  ${cascading.toimg}`} />
                            </div>
                            <div>
                                <p className={`${cascading.coinname} mb-0`}>DSP-BNB</p>
                                <p className={`${cascading.coinnamegrey} mb-0`}>#ID-124</p>
                            </div>


                        </div>

                    </>
                );
            }

        },
        {
            key: "tvl",
            text: "TVL",
            className: "tvl",
            align: "left",
            sortable: true,

        },
        {
            key: "aprrange",
            text: "APR Range",
            className: "aprrange",
            sortable: true,
            width: 80,
            cell: records => {
                return (
                    <div className='d-flex align-items-center'>
                        <p className={`mb-0 d-flex align-items-center ${cascading.labelvalue} ${cascading.calculatorbtn}`}>10.39%</p>
                        <div>
                            <OverlayTrigger
                                className={`${cascading.tooltip_style}`}
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <p className='mb-1'>Swap fees APR: 8.02%</p>
                                        <p className='mb-1'>Farm APR: 0.02% - 2.89%</p>
                                        <p className='mb-0'>YUM rewards: +3.14%</p>

                                    </Tooltip>
                                }
                            >
                                <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
                            </OverlayTrigger>
                        </div>
                    </div>
                    // <img src={tick} alt='tick' className={`img-fluid`} width={17} height={17}/>
                )

            }
        },
        {
            key: "properties",
            text: "Properties",
            className: "properties",
            align: "left",
            sortable: true,
            cell: records => {
                console.log(records, "recordes")
                return (
                    <div className='d-flex align-items-center justify-content-start'>
                        <div className='pe-2'>
                            <OverlayTrigger
                                className={`${cascading.tooltip_style}`}
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <p className='mb-0'>Yield bearing</p>

                                    </Tooltip>
                                }
                            >
                                <Button className={records.isbeared ? `${cascading.tooltip_button} ${cascading.tooltip_button_active} ms-0` : `${cascading.tooltip_button} ${cascading.tooltip_button_inactive} ms-0`}><FaCoins /></Button>
                            </OverlayTrigger>
                        </div>

                        <div className='px-2'>
                            <OverlayTrigger
                                className={`${cascading.tooltip_style}`}
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <p className='mb-0'>Lock/Unlock position</p>

                                    </Tooltip>
                                }
                            >
                                <Button className={records.islocked ? `${cascading.tooltip_button} ${cascading.tooltip_button_active} ms-0` : `${cascading.tooltip_button} ${cascading.tooltip_button_inactive} ms-0`}><FaLock /></Button>
                            </OverlayTrigger>
                        </div>

                        <div className='px-2'>
                            <OverlayTrigger
                                className={`${cascading.tooltip_style}`}
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <p className='mb-0'>Boost/Unboost position</p>

                                    </Tooltip>
                                }
                            >
                                <Button className={records.isboosted ? `${cascading.tooltip_button} ${cascading.tooltip_button_active} ms-0` : `${cascading.tooltip_button} ${cascading.tooltip_button_inactive} ms-0`}><FaRocket /></Button>
                            </OverlayTrigger>
                        </div>




                    </div>
                )

            }
        },
        {
            key: "rewards",
            text: "Pending Rewards",
            className: "rewards",
            sortable: true,
            align: "left",
            cell: records => {
                return (
                    <div>
                        <p className={`${cascading.coinname} mb-0`}>$0.05</p>
                        <p className={`${cascading.coinnamegrey} mb-0`}><FaAngleLeft />0.001 DSP</p>
                    </div>
                )

            }
        },
        {
            key: "action",
            text: "",
            className: "action",
            sortable: true,
            align: "left",
            cell: records => {
                return (
                    <div className='d-flex align-items-center justify-content-between'>
                        {/* <button className='btn_trans_icon_table' onClick={() => { setPositionmodal(false); setAddpositionmodal(true); }}> */}
                        <button className='btn_trans_icon_table' onClick={() => { setPositionmodal(false);  setmodalcoming(true) ; }}>

                            <FaDownload />
                        </button>

                        {/* <button className='btn_trans_icon_table' onClick={() => { setPositionmodal(false); setHarvestpositionmodal(true); }}> */}
                        <button className='btn_trans_icon_table' onClick={() => { setPositionmodal(false); setmodalcoming(true); }}>

                            <FaUpload />
                        </button>

                        {/* <button className='btn_trans_icon_table' onClick={() => { setPositionmodal(false); setLockpositionmodal(true); }}> */}
                        <button className='btn_trans_icon_table' onClick={() => { setPositionmodal(false); setmodalcoming(true); }}>

                            <FaLock />
                        </button>

                        {/* <button className='btn_trans_icon_table' onClick={() => { setPositionmodal(false); setBoostpositionmodal(true); }}> */}
                        <button className='btn_trans_icon_table' onClick={() => { setPositionmodal(false); setmodalcoming(true); }}>

                            <FaRocket />
                        </button>


                    </div>
                )

            }
        },


    ];

    const records = [
        {
            "pool": "",
            "tvl": "$2.62",
            "aprrange": "",
            "deposits": "0",
            "rewards": "",
            "action": "",
            "isbeared": true,
            "islocked": false,
            "isboosted": false
        },
        {
            "pool": "",
            "tvl": "$4.3",
            "aprrange": "",
            "deposits": "0",
            "rewards": "",
            "action": "",
            "isbeared": false,
            "islocked": true,
            "isboosted": false

        },
        {
            "pool": "",
            "tvl": "$4.3",
            "aprrange": "",
            "deposits": "0",
            "rewards": "",
            "action": "",
            "isbeared": false,
            "islocked": false,
            "isboosted": true

        },
        {
            "pool": "",
            "tvl": "$4.3",
            "aprrange": "",
            "deposits": "0",
            "rewards": "",
            "action": "",
            "isbeared": false,
            "islocked": false,
            "isboosted": false

        },
        {
            "pool": "",
            "tvl": "$4.3",
            "aprrange": "",
            "deposits": "0",
            "rewards": "",
            "action": "",
            "isbeared": true,
            "islocked": true,
            "isboosted": true

        }

    ];


    const userdetail = useSelector((state) => state.admin)
    return (
        <>
            <div className={`${cascading.innerpages} ${cascading.launchpadpage} ${cascading.pooltable} ${cascading.pooltablewidth}`}>


                <div className={`${cascading.tabstyle} mt-3 table-responsive`} id='launchpadtable' >

                    <ReactDatatable
                        // data={data}
                        className={`${cascading.datatablestyle} ${cascading.datatablestylenohover}`}
                        config={config}
                        columns={columns}
                        records={records}
                    // onRowClicked={()=>{setPositionmodal(true)}}


                    />
                </div>
            </div>





            {positionmodal && <Positionmodal show={positionmodal}
                addmodal={() => setAddpositionmodal(true)}
                harvestmodal={() => setHarvestpositionmodal(true)}
                lockmodal={() => setLockpositionmodal(true)}
                boostmodal={() => setBoostpositionmodal(true)}



                onHide={() => setPositionmodal(false)} />}




            {addpositionmodal && <Addpositionmodal show={addpositionmodal} onSuccess={() => { setModalsucess(true); }} onhideposition={() => { setPositionmodal(false); }} onHide={() => { setAddpositionmodal(false) }} />}
            {harvestpositionmodal && <Harvestpositionmodal show={harvestpositionmodal} onSuccess={() => { setModalsucess(true); }} positionmodal={() => setPositionmodal(true)} onhideposition={() => { setPositionmodal(false); }} onHide={() => { setHarvestpositionmodal(false) }} />}
            {lockpositionmodal && <Lockpositionmodal show={lockpositionmodal} onSuccess={() => { setModalsucess(true); }} positionmodal={() => setPositionmodal(true)} onhideposition={() => { setPositionmodal(false); }} onHide={() => { setLockpositionmodal(false) }} />}
            {boostpositionmodal && <Boostpositionmodal show={boostpositionmodal} onSuccess={() => { setModalsucess(true); }} positionmodal={() => setPositionmodal(true)} onhideposition={() => { setPositionmodal(false); }} onHide={() => { setBoostpositionmodal(false) }} />}
            {modalsucess && <Addsuccessmodal show={modalsucess} onHide={() => { setModalsucess(false); }} />}
            {modalcoming && <ComingSoonmodal show={modalcoming} onHide={() => { setmodalcoming(false); }} />}

        </>

    )
}
