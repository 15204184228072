import React from 'react'
import cascading from '../assests/css/all.module.css';
import { BiRightArrowAlt } from "react-icons/bi";
export default function Balancecard(props) {
    return (
        <>
            <div className={`${cascading.balancecard}`}>
                <div>
                    <p className={`${cascading.labelname}`}>{props.datas.label}</p>
                    <p className={`${cascading.labelvalue}`}>{props.datas.value}</p>
                </div>
                <div>
                    <img className={`img-fluid ${cascading.balanceimg}`} src={props.datas.img} alt='img' />
                </div>
            </div>



        </>
    )
}
