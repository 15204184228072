import React, { useState } from 'react'
import { Modal, Button, Tab, Tabs } from 'react-bootstrap';
import cascading from '../assests/css/positionmodalstyle.module.css';
import toast, { Toaster } from 'react-hot-toast';
import Accordion from 'react-bootstrap/Accordion';
import CopyToClipboard from "react-copy-to-clipboard";
import { FaInfoCircle, FaChevronLeft, FaCopy, FaAngleUp, FaExternalLinkAlt, FaRegQuestionCircle,FaRegTimesCircle, FaDownload, FaUpload, FaLock, FaRocket, FaCoins, FaCheckCircle } from "react-icons/fa";


import { FaArrowDown, FaArrowRight } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import bnblogo from '../assests/images/bnb.png';
import coinlogo from '../assests/images/logomini.png';





import { IoMdClose } from "react-icons/io";
export default function Boostpositionmodal(props) {
 
    const [boostpositionmodal, setBoostpositionmodal] = useState(true);
    const [isyielded, setIsyielded] = useState(true);
    const [islocked, setIslocked] = useState(true);
    const [isboosted, setIsboosted] = useState(false);


    


  



  return (
    <>
    <div className={`${cascading.networkmodalstyle}`}>
      <Modal centered show={boostpositionmodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>Boost/Unboost Position</h4>
          <IoMdClose className={`${cascading.closebtn}`} onClick={()=>{props.onHide();props.onhideposition();}} />

        </Modal.Header>
        <Modal.Body className={`pt-0 ${cascading.modalclr} ${cascading.modalbody}`}>
          
<div>

<div className={`${cascading.imgsec}`}>
              <div className={`${cascading.leftimgsec}`}>
                  <img src={bnblogo} alt='img' className={`img-fluid ${cascading.fromimg}`} />
                  <img src={coinlogo} alt='img' className={`img-fluid  ${cascading.toimg}`} />
              </div>
              <div>
              <p className={`${cascading.coinname} mb-0`}>DSP-BNB</p>
              <p className={`${cascading.coinnamegrey} mb-0`}>#ID-124</p>
              </div>
           
          
          </div>


{/* <p className={`${cascading.tokenvalue}`}>$1.03 - <span>20.03%</span> APR</p> */}
<p className={`${cascading.tokenvaluedesc} mt-3`}>Allocate xRAIL to your spNFT for more yield</p>
</div>
<div className={`${cascading.tabstyle} mt-5`}>
<Tabs
      defaultActiveKey="boost"
      id="uncontrolled-tab-example"
      className={`${cascading.tabstyletabs} mt-3 mb-5`}
    >
      <Tab eventKey="boost" title="Boost">
        
<>
           
      <p className={`${cascading.innerheadwhitetitle} mt-2`}>Amount
          </p>
          <p className={`${cascading.tokenvaluedesc} text-start`}>You need to own DSP-BNB LP tokens to directly add more liquidity to this position. If that's not the case, head to the liquidity page first.</p>


          <div className={`${cascading.inputsec} my-3`}>
                    <div className={`input-group mb-3`}>
                        <input
                            type="text"
                            className={`form-control`}
                            placeholder="0"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                        />
                        <div className={`input-group-append`} >
                            <button className={` ${cascading.inputspan}`} id="basic-addon1">
                                Max
                            </button>
                        </div>
                    </div>
                    <p className={`${cascading.balsec}`}>balance : 0.00000000000672770063 DSP-BNB</p>
                </div>

                <p className={`${cascading.innerheadwhitetitle} mt-4`}>Estimates
          </p>
        

          <div className='row mt-3'>
            <div className='col-8 col-md-8'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Boost Multiplier</p>
            </div>
            <div className='col-4 col-md-4'>
              <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>x1 <FaArrowRight /> x1.29</p>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-8 col-md-8'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Farm base APR</p>
            </div>
            <div className='col-4 col-md-4'>
              <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>1.02%</p>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-8 col-md-8'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Bonus APR</p>
            </div>
            <div className='col-4 col-md-4'>
              <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>0%</p>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-8 col-md-8'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Earned fees APR</p>
            </div>
            <div className='col-4 col-md-4'>
              <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>6.38%</p>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-8 col-md-8'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total APR</p>
            </div>
            <div className='col-4 col-md-4'>
              <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>7.41%</p>
            </div>
          </div>

        




<div className='mt-4'>


                <button className={`btn ${cascading.connectwalletbtn}`} type='button' onClick={()=>{props.onHide();props.onSuccess();}}>Boost</button>
          <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button>

          </div>
          </>
      </Tab>
      <Tab eventKey="unboost" title="Unboost">
        
<>
           
      <p className={`${cascading.innerheadwhitetitle} mt-2`}>Amount
          </p>
          <p className={`${cascading.tokenvaluedesc} text-start`}>You need to own DSP-BNB LP tokens to directly add more liquidity to this position. If that's not the case, head to the liquidity page first.</p>


          <div className={`${cascading.inputsec} my-3`}>
                    <div className={`input-group mb-3`}>
                        <input
                            type="text"
                            className={`form-control`}
                            placeholder="0"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                        />
                        <div className={`input-group-append`} >
                            <button className={` ${cascading.inputspan}`} id="basic-addon1">
                                Max
                            </button>
                        </div>
                    </div>
                    <p className={`${cascading.balsec}`}>balance : 0.00000000000672770063 DSP-BNB</p>
                </div>

                <p className={`${cascading.innerheadwhitetitle} mt-4`}>Estimates
          </p>
        

          <div className='row mt-3'>
            <div className='col-8 col-md-8'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Boost Multiplier</p>
            </div>
            <div className='col-4 col-md-4'>
              <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>x1 <FaArrowRight /> x1.29</p>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-8 col-md-8'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Farm base APR</p>
            </div>
            <div className='col-4 col-md-4'>
              <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>1.02%</p>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-8 col-md-8'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Bonus APR</p>
            </div>
            <div className='col-4 col-md-4'>
              <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>0%</p>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-8 col-md-8'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Earned fees APR</p>
            </div>
            <div className='col-4 col-md-4'>
              <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>6.38%</p>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-8 col-md-8'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total APR</p>
            </div>
            <div className='col-4 col-md-4'>
              <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>7.41%</p>
            </div>
          </div>

        




<div className='mt-4'>


                <button className={`btn ${cascading.connectwalletbtn}`} type='button' onClick={()=>{props.onHide();props.onSuccess();}}>Unboost</button>
          <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button>

          </div>
          </>
      </Tab>
    
    </Tabs>
    </div>



{/* <div className='p-3 text-center'>
<button className={`btn ${cascading.connectwalletbtn}`} type='button'>Close</button>

</div> */}







        </Modal.Body>

      </Modal>
    </div>
</>
  )
}

