import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/positionmodalstyle.module.css';
import toast, { Toaster } from 'react-hot-toast';
import Accordion from 'react-bootstrap/Accordion';
import CopyToClipboard from "react-copy-to-clipboard";
import { FaInfoCircle, FaChevronLeft,FaTrophy, FaCopy, FaAngleUp, FaExternalLinkAlt, FaRegQuestionCircle,FaRegTimesCircle, FaDownload, FaUpload, FaLock, FaRocket, FaCoins, FaCheckCircle } from "react-icons/fa";


import { FaArrowDown } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import bnblogo from '../assests/images/bnb.png';
import coinlogo from '../assests/images/logomini.png';





import { IoMdClose } from "react-icons/io";
export default function Harvestpositionmodal(props) {
 
    const [harverstpositionmodal, setHarvestpositionmodal] = useState(true);
   


  return (
    <>
    <div className={`${cascading.networkmodalstyle}`}>
      <Modal centered show={harverstpositionmodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>Harvest Position</h4>
          <IoMdClose className={`${cascading.closebtn}`} onClick={()=>{props.onHide();props.onhideposition();}} />

        </Modal.Header>
        <Modal.Body className={`pt-0 ${cascading.modalclr} ${cascading.modalbody}`}>
          
<div>

<div className={`${cascading.imgsec}`}>
              <div className={`${cascading.leftimgsec}`}>
                  <img src={bnblogo} alt='img' className={`img-fluid ${cascading.fromimg}`} />
                  <img src={coinlogo} alt='img' className={`img-fluid  ${cascading.toimg}`} />
              </div>
              <div>
              <p className={`${cascading.coinname} mb-0`}>DSP-BNB</p>
              <p className={`${cascading.coinnamegrey} mb-0`}>#ID-124</p>
              </div>
           
          
          </div>


{/* <p className={`${cascading.tokenvalue}`}>$1.03 - <span>20.03%</span> APR</p> */}
<p className={`${cascading.tokenvaluedesc} mt-3`}>Collect your farming & rewards</p>




           
          <p className={`${cascading.innerheadwhitetitle} mt-4`}>All Rewards
          </p>
          <p className={`${cascading.tokenvaluedesc} mb-1 text-start`}>Harvest farming and rewards</p>

          <p className={`${cascading.tokenvaluedesctheme} text-start`}>$<FaChevronLeft />0.01</p>

         
          <p className={`${cascading.innerheadwhitetitle} mt-4`}>spNFT Rewards
          </p>
          <p className={`${cascading.tokenvaluedesc} mb-1 text-start`}>Harvest farming rewards only</p>

          <p className={`${cascading.tokenvaluedesctheme} text-start`}>$<FaChevronLeft />0.01</p>

        

                <p className={`${cascading.innerheadwhitetitle} mt-4`}>Rewards Breakdown
          </p>
            
        

      
       

         


          <div className='row mt-3 align-items-center'>
            <div className='col-12 col-sm-4 col-md-4'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>GRAIL
              <span className='px-2'>farm</span></p>
            </div>
            <div className='col-12 col-sm-8 col-md-8'>
              <p className={`mb-0 text-end d-flex align-items-center justify-content-sm-end ${cascading.innerheadwhitesm}`}>
                <span>($<FaChevronLeft />0.001)</span>
                <FaChevronLeft />0.000000001
                <div className={`${cascading.tropyhytheme} ms-2`}>
                <FaTrophy />
                </div>
                    </p>
            </div>
          </div>

          <div className='row mt-3 align-items-center'>
            <div className='col-12 col-sm-4 col-md-4'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>YUM
              <span className='px-2'>farm</span></p>
            </div>
            <div className='col-12 col-sm-8 col-md-8'>
              <p className={`mb-0 text-end d-flex align-items-center justify-content-sm-end ${cascading.innerheadwhitesm}`}>
                <span>($<FaChevronLeft />0.001)</span>
                <FaChevronLeft />0.000000001
                <div className={`${cascading.tropyhyblack} ms-2`}>
                <FaTrophy />
                </div>
                    </p>
            </div>
          </div>

      

     </div>
        




<div className='mt-5'>


                <button className={`btn ${cascading.connectwalletbtn}`} type='button' onClick={()=>{props.onHide();props.onSuccess();}}>Harvest</button>
          <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button>

          </div>



{/* <div className='p-3 text-center'>
<button className={`btn ${cascading.connectwalletbtn}`} type='button'>Close</button>

</div> */}







        </Modal.Body>

      </Modal>
    </div>
</>
  )
}

