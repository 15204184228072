import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/apycalculatorstyle.module.css';
import { FaArrowDown } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";




import { IoMdClose } from "react-icons/io";
import { FaArrowRight } from 'react-icons/fa';
export default function Unboostmodal(props) {
 


  const [unboostmodal, setUnboostmodal] = useState(true);

  



  return (
    <>
    <div className={`${cascading.networkmodalstyle}`}>
      <Modal centered show={unboostmodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>Unboost your position</h4>
          <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

        </Modal.Header>
        <Modal.Body className={`pt-0 ${cascading.modalclr} ${cascading.modalbody}`}>
          
<div>
          <p className={`${cascading.innerheadwhite}`}>Amount
          </p>

          <div className={`${cascading.inputsec} my-3`}>
                    <div className={`input-group mb-3`}>
                        <input
                            type="text"
                            className={`form-control`}
                            placeholder="0"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                        />
                        <div className={`input-group-append`} >
                            <button className={` ${cascading.inputspan}`} id="basic-addon1">
                                Max
                            </button>
                        </div>
                    </div>
                    <p className={`${cascading.balsec}`}>balance : 0.00000000123000 YUM</p>
                </div>

                <p className={`${cascading.innerheadwhitetitle} mt-4`}>Estimates
          </p>

          
          <div className='row mt-3'>
            <div className='col-8 col-md-7'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Boost multiplier</p>
            </div>
            <div className='col-4 col-md-5'>
              <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>x2 <FaArrowRight className='px-1'/>x1</p>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-8 col-md-7'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Farm base APR</p>
            </div>
            <div className='col-4 col-md-5'>
              <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>0.37%</p>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-8 col-md-7'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Bonus APR</p>
            </div>
            <div className='col-4 col-md-5'>
              <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>0.37% <FaArrowRight className='px-1' />0%</p>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-8 col-md-7'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Earned fees APR</p>
            </div>
            <div className='col-4 col-md-5'>
              <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>13/73%</p>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-8 col-md-7'>
              <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total APR</p>
            </div>
            <div className='col-4 col-md-5'>
              <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>20.40% <FaArrowRight className='px-1' />20.09%</p>
            </div>
          </div>


                <Button className={`${cascading.btnstyle} mt-4`} onClick={()=>{props.onHide();props.onSuccess();}}>
           Unboost
          </Button>

          <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button>

   
                </div>
        











{/* <div className='p-3 text-center'>
<button className={`btn ${cascading.connectwalletbtn}`} type='button'>Close</button>

</div> */}







        </Modal.Body>

      </Modal>
    </div>
</>
  )
}

