import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/positionmodalstyle.module.css';
import toast, { Toaster } from 'react-hot-toast';
import Accordion from 'react-bootstrap/Accordion';
import CopyToClipboard from "react-copy-to-clipboard";
import { FaArrowRight, FaChevronLeft, FaCopy, FaAngleUp, FaExternalLinkAlt, FaRegQuestionCircle, FaRegTimesCircle, FaDownload, FaUpload, FaLock, FaRocket, FaCoins, FaCheckCircle } from "react-icons/fa";


import { FaArrowDown } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import bnblogo from '../assests/images/bnb.png';
import coinlogo from '../assests/images/logomini.png';





import { IoMdClose } from "react-icons/io";
export default function Positionmodal(props) {

  const [positionmodal, setPositionmodal] = useState(true);
  const [isyielded, setIsyielded] = useState(true);
  const [islocked, setIslocked] = useState(true);
  const [isboosted, setIsboosted] = useState(false);









  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal centered show={positionmodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
          <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
            <h4 className={`${cascading.modaltitle}`}>Position</h4>
            <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

          </Modal.Header>
          <Modal.Body className={`pt-0 ${cascading.modalclr} ${cascading.modalbody}`}>

            <div>

              <div className={`${cascading.imgsec}`}>
                <div className={`${cascading.leftimgsec}`}>
                  <img src={bnblogo} alt='img' className={`img-fluid ${cascading.fromimg}`} />
                  <img src={coinlogo} alt='img' className={`img-fluid  ${cascading.toimg}`} />
                </div>
                <div>
                  <p className={`${cascading.coinname} mb-0`}>DSP-BNB</p>
                  <p className={`${cascading.coinnamegrey} mb-0`}>#ID-124</p>
                </div>


              </div>


              <p className={`${cascading.tokenvalue}`}>$1.03 - <span>20.03%</span> APR</p>
              <p className={`${cascading.tokenvaluedesc}`}>This position has<span>$<FaChevronLeft />0.001 %</span> pending farming rewards</p>


              <div className='d-flex align-items-start justify-content-center mt-4'>
                <div className='text-center px-2'>
                  <button className={`btn mx-2 ${cascading.themelink} ${cascading.buttonstyle_ul}`} onClick={() => { props.addmodal(); props.onHide(); }}>
                    <FaDownload />
                  </button>
                  <p className={`${cascading.tokenvaluedesc} mt-1`}>Add</p>
                </div>

                <div className='text-center px-2'>
                  <button className={`btn mx-2 ${cascading.themelink} ${cascading.buttonstyle_ul}`} onClick={() => { props.harvestmodal(); props.onHide(); }}>
                    <FaUpload />
                  </button>
                  <p className={`${cascading.tokenvaluedesc} mt-1`}>Withdraw</p>

                </div>
                <div className='text-center px-2'>

                  <button className={`btn mx-2 ${cascading.themelink} ${cascading.buttonstyle_ul}`} onClick={() => { props.lockmodal(); props.onHide(); }}>
                    <FaLock />
                  </button>
                  <p className={`${cascading.tokenvaluedesc} mt-1`}>Lock</p>

                </div>
                <div className='text-center px-2'>

                  <button className={`btn mx-2 ${cascading.themelink} ${cascading.buttonstyle_ul}`} onClick={() => { props.boostmodal(); props.onHide(); }}>
                    <FaRocket />
                  </button>
                  <p className={`${cascading.tokenvaluedesc} mt-1`}>Yield Boost</p>

                </div>
              </div>



              <p className={`${cascading.innerheadwhitetitle} mt-2`}>Properties
              </p>


              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>
                    <div className='d-flex align-items-center'>
                      {
                        isyielded ?
                          <FaCheckCircle className={`${cascading.themeicon}`} />
                          :
                          <FaRegTimesCircle className={`${cascading.invisibleicon}`} />

                      }
                      {
                        isyielded ?
                          "Yield-bearing"
                          :
                          "Unyielded"
                      }
                    </div>
                  </p>
                </div>
                <div className='col-4 col-md-4'>
                  {
                    isyielded ?
                      <>
                        <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>6.35%</p>
                        <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>Farm APR</p>
                      </>
                      :
                      <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>-</p>

                  }

                </div>
              </div>
              <hr className={`${cascading.hrstyle}`} />

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>
                    <div className='d-flex align-items-center'>
                      {
                        islocked ?
                          <FaCheckCircle className={`${cascading.themeicon}`} />
                          :
                          <FaRegTimesCircle className={`${cascading.invisibleicon}`} />

                      }
                      {
                        islocked ?
                          <div className='d-flex flex-column'>

                            <p className={`mb-0 text-start ${cascading.innerheadthemesm}`}>Locked</p>
                            <p className={`mb-0 text-start ${cascading.innerheadwhitesm}`}>1.02x multiplier</p>
                          </div>

                          :
                          "Unlocked"
                      }
                    </div>
                  </p>
                </div>
                <div className='col-4 col-md-4'>
                  {
                    islocked ?
                      <>
                        <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>3D 23h 17min 3s</p>
                        <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>Remaining time</p>
                      </>
                      :
                      <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>-</p>

                  }

                </div>
              </div>
              <hr className={`${cascading.hrstyle}`} />


              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>
                    <div className='d-flex align-items-center'>
                      {
                        isboosted ?
                          <FaCheckCircle className={`${cascading.themeicon}`} />
                          :
                          <FaRegTimesCircle className={`${cascading.invisibleicon}`} />

                      }
                      {
                        isboosted ?
                          "Boosted"
                          :
                          "Unboosted"
                      }
                    </div>
                  </p>

                </div>
                <div className='col-4 col-md-4'>
                  {
                    isyielded ?
                      <>
                        <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>6.35%</p>
                        <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>Farm APR</p>
                      </>
                      :
                      <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>-</p>

                  }

                </div>
              </div>






              <p className={`${cascading.innerheadwhitetitle} mt-4`}>Data Breakdown
              </p>


              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Value</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>$2.05</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Swap fees APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>6.38%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Farm base APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>1.02%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Lock bonus APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>0%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>7.41%</p>
                </div>
              </div>


            </div>





            <div className='mt-4'>


              <button className={`btn ${cascading.connectwalletbtn}`} type='button'>Harvest</button>
              <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Close</button>

            </div>



            {/* <div className='p-3 text-center'>
<button className={`btn ${cascading.connectwalletbtn}`} type='button'>Close</button>

</div> */}







          </Modal.Body>

        </Modal>
      </div>
    </>
  )
}

