import React, { useState } from 'react'
import cascading from '../assests/css/all.module.css';
import { Button, ButtonGroup } from 'react-bootstrap';
import { FaInfoCircle } from "react-icons/fa";
import Countdown from 'react-countdown';

import bnblogo from '../assests/images/bnb.png';
import bsclogo from '../assests/images/bsc.png';

import coinlogo from '../assests/images/logomini.png';
export default function Timercard(props) {
   
// Random component
const Completionist = () => <span className={`${cascading.themetime}`}>00D 00h 00m 00s</span>;

const formatTime = (time) => {
    return String(time).padStart(2, '0')
  }

// Renderer callback with condition
const renderer = ({ days,hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return Completionist();
  } else {
    // Render a countdown
    return <span className={`${cascading.themetime}`}>{formatTime(days)}D {formatTime(hours)}h {formatTime(minutes)}m {formatTime(seconds)}s</span>;
  }
};
 
    return (
        <div className={`${cascading.approvecard} mb-4`}>
       
            <div className={`${cascading.innercard} ${cascading.dashcard}`}>

                <p className={`${cascading.head} ${cascading.headfelx} mb-0 justify-content-center`}>
                    <p className='mb-0 text-center d-flex align-items-center'>
            <FaInfoCircle className={`${cascading.infocircle} me-3`} />

                <span className='pe-1'>Next epoch will start in</span>  <Countdown 
    date={Date.now() + 5000}
    renderer={renderer}
  />
                </p>
<div>
              

                </div>

                </p>
              
           

            

            </div>






        </div>
    )
}
