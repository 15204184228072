import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/addliquiditystyle.module.css';
import toast, { Toaster } from 'react-hot-toast';
import Accordion from 'react-bootstrap/Accordion';
import CopyToClipboard from "react-copy-to-clipboard";
import { FaRegQuestionCircle, FaArrowRight, FaChevronLeft, FaCopy } from "react-icons/fa";


import { FaArrowDown } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import bnblogo from '../assests/images/bnb.png';




import { IoMdClose } from "react-icons/io";
export default function Addliquiditymodal(props) {

  const [liquiditymodal, setLiquiditymodal] = useState(true);







  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal centered show={liquiditymodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
          <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
            <h4 className={`${cascading.modaltitle}`}>Add Liquidity</h4>
            <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

          </Modal.Header>
          <Modal.Body className={`pt-0 ${cascading.modalclr} ${cascading.modalbody}`}>

            <div>

              <div className={`${cascading.tokenrow}`}>
                <img src={bnblogo} alt='coin' className={`img-fluid ${cascading.timg}`} />
                <div>
                  <p className={`${cascading.name}`}>BNB</p>
                  <p className={`${cascading.desc}`}>1</p>
                </div>
              </div>

              <div className={`${cascading.createaccordion} mb-4 mt-4`}>

                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey={0}>
                    <Accordion.Header>
                      <div className={`mb-0 text-start pt-1 ${cascading.labelflex}`}>
                        <p className={`mb-3 text-start ${cascading.label}`}>
                          1 DSP = 0.00089 ETH
                          <span className='ps-1'>($2.87)</span></p>
                        <p className={`mb-0 text-start ${cascading.label}`}>1 ETH = 1,311.089786789 DSP
                          <span className='ps-1'>($3,251.56)</span></p>

                      </div>
                    </Accordion.Header>

                    <Accordion.Body className={`pb-1`}>
                      <div className={``}>
                        <div className={`${cascading.valuecardsec} ${cascading.valuecardsecnpng} mb-4`}>
                          <div className={`${cascading.secrow}`}>
                            <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>Pair Type

                            </p>
                            <p className={`mb-0 ${cascading.labels}`}>Volatile
                            </p>
                          </div>

                          <div className={`${cascading.secrow} mt-3`}>
                            <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>DSP Swap Rate

                            </p>
                            <p className={`mb-0 ${cascading.labels}`}>1 DSP <FaArrowRight /> 0.0000865 ETH
                            </p>
                          </div>

                          <div className={`${cascading.secrow} mt-3`}>
                            <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>ETH Swap Rate

                            </p>
                            <p className={`mb-0 ${cascading.labels}`}>1 ETH <FaArrowRight /> 0.0000865 DSP
                            </p>
                          </div>

                          <div className={`${cascading.secrow} mt-3`}>
                            <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>DSP/ETH liquidity ratio

                            </p>
                            <p className={`mb-0 ${cascading.labels}`}>0.0000824
                            </p>
                          </div>

                          <div className={`${cascading.secrow} mt-3`}>
                            <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>ETH/DSP liquidity ratio

                            </p>
                            <p className={`mb-0 ${cascading.labels}`}>1,131.0000824
                            </p>
                          </div>

                          <div className={`${cascading.secrow} mt-3`}>
                            <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>Pool Share

                            </p>
                            <p className={`mb-0 ${cascading.labels}`}><FaChevronLeft />0.001 %
                            </p>
                          </div>

                          <div className={`${cascading.secrow} mt-3`}>
                            <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>LP Address

                            </p>
                            <div className='d-flex align-items-center justify-content-between'>
                              <p className={`mb-0 ${cascading.labels}`}>0xBFCa...7B2Be3
                              </p>

                              <CopyToClipboard
                                text={"4JWktLr39ZbyutVnym7wKxV1RDoJz7ifDYxMUHDmAbPB"}
                                onCopy={() =>
                                  toast('Address copied', {
                                    duration: 2000,
                                    position: 'top-right',

                                    // Styling
                                    style: { background: "#1c2029", color: "#fff", minWidth: 200 },
                                    className: '',

                                    // Custom Icon

                                    // Change colors of success/error/loading icon
                                    iconTheme: {
                                      primary: '#000',
                                      secondary: '#fff',
                                    },

                                    // Aria
                                    ariaProps: {
                                      role: 'status',
                                      'aria-live': 'polite',
                                    },
                                  })
                                }

                              >
                                <button className={`mb-0 ${cascading.copybtntrans}`}><FaCopy /></button>
                              </CopyToClipboard>
                            </div>


                          </div>

                          <div className={`${cascading.secrow} mt-3`}>
                            <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>NFT Pool Address

                            </p>
                            <div className='d-flex align-items-center justify-content-between'>
                              <p className={`mb-0 ${cascading.labels}`}>0xBFCa...7B2Be3
                              </p>

                              <CopyToClipboard
                                text={"4JWktLr39ZbyutVnym7wKxV1RDoJz7ifDYxMUHDmAbPB"}
                                onCopy={() =>
                                  toast('Address copied', {
                                    duration: 2000,
                                    position: 'top-right',

                                    // Styling
                                    style: { background: "#1c2029", color: "#fff", minWidth: 200 },
                                    className: '',

                                    // Custom Icon

                                    // Change colors of success/error/loading icon
                                    iconTheme: {
                                      primary: '#000',
                                      secondary: '#fff',
                                    },

                                    // Aria
                                    ariaProps: {
                                      role: 'status',
                                      'aria-live': 'polite',
                                    },
                                  })
                                }

                              >
                                <button className={`mb-0 ${cascading.copybtntrans}`}><FaCopy /></button>
                              </CopyToClipboard>
                            </div>


                          </div>


                        </div>

                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                </Accordion>
              </div>


              <p className={`${cascading.innerheadwhitetitle}`}>Boost Options
              </p>



              <div className='row mt-4'>
                <div className='col-12 col-md-5'>
                  <p className={`mb-0 ${cascading.innerheadwhite}`}>Lock duration
                  </p>
                  <a href="javascript:void(0)" className={`${cascading.themelink}`}>Set Max</a>
                </div>
                <div className='col-12 col-md-7 mt-3 mt-md-0'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <button className={`btn ${cascading.themelink} ${cascading.buttonstyle_ul}`}>
                      <FaMinus />
                    </button>
                    <div>
                      <p className={`mb-1 text-center ${cascading.textgreydesc}`}>Months</p>
                      <p className={`mb-0 text-center ${cascading.textwhitedesc}`} >0</p>


                    </div>
                    <div>
                      <p className={`mb-1 text-center ${cascading.textgreydesc}`}>Days</p>
                      <p className={`mb-0 text-center ${cascading.textwhitedesc}`} >0</p>


                    </div>
                    <button className={`btn ${cascading.themelink} ${cascading.buttonstyle_ul}`}>
                      <FaPlus />
                    </button>
                  </div>
                  <p className={`mb-1 text-end mt-3 ${cascading.textgreydesc}`}>1.64% lock bonus (x1.02)</p>
                </div>
              </div>




              <p className={`${cascading.innerheadwhitetitle} mt-4`}>Estimates
              </p>


              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Deposit value</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>$2.04</p>
                </div>
              </div>



              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Swap fees APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>6.38%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Farm base APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>1.02%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Lock bonus APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>0%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>7.41%</p>
                </div>
              </div>


            </div>





            <div className='mt-4'>


              <button className={`btn ${cascading.connectwalletbtn}`} type='button' onClick={() => { props.onSuccess(); props.onHide() }}>Create Position</button>
              <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button>

            </div>



            {/* <div className='p-3 text-center'>
<button className={`btn ${cascading.connectwalletbtn}`} type='button'>Close</button>

</div> */}







          </Modal.Body>

        </Modal>
      </div>
    </>
  )
}

