import React, { useState } from 'react';
import cascading from '../../assests/css/all.module.css';
import Footer from '../../common/footer'
import Dashcard from '../../components/dashcard';
import Yourallocation from '../../components/yourallocation';
import Yourdividents from '../../components/yourdividents';
import Connectcard from "../../components/connectcard";
import Currentepoch from "../../components/currentepoch";
import Nextepoch from "../../components/nextepoch";
import Pendingrewards from "../../components/pendingrewards";
import Timercard from "../../components/timercard";


import Balancecard from '../../components/balancecard';
import Header from '../../common/header';
import { useNavigate } from 'react-router-dom';

export default function Dividents() {
    const navigate = useNavigate()
    const [isconnect, setIsconnect] = useState(true);
    const [detailcard, setDetailcard] = useState([
        {
            label: "Total allocations",
            value: "11,500 YUM",
            img: require("../../assests/images/total_icon.png")

        },
        {
            label: "Deallocation fee",
            value: "0.5%",
            img: require("../../assests/images/fee_icon.png")

        },
        {
            label: "Current epoch",
            value: "$90.6k",
            img: require("../../assests/images/current_icon.png")

        },
        {
            label: "Epoch earnings / YUM",
            value: "$7.88",
            img: require("../../assests/images/current_icon1.png")

        },
        {
            label: "APY",
            value: "18.32%",
            img: require("../../assests/images/apy_icon.png")

        }
    ])
    const [dashdetail, setDashdetail] = useState([
        {
            name: "Yield booster",
            desc: "Boost your staking yields by up to +100% by adding PZLX to any eligible position.",
            img: require("../../assests/images/folder.png"),
            mybal:"0",
            total:"427",
            fees:"0.5",
            path:"/yieldbooster"

        },
        {
            name: "Launchpad",
            desc: "Get perks and benefits from every project on PretzelSwap's launchpad by staking your PZLX here.",
            img: require("../../assests/images/folder.png"),
            mybal:"0",
            total:"376",
            fees:"0.5",
            path:"/launchpad"

        },
    ])
    return (
        <>
        <Header/>
            <div className={`${cascading.innerpages} ${cascading.PZLXpage}`}>
                <div className={`container`}>
                    <div className={`row mx-0 ${cascading.headrow}`}>
                        <p className={`${cascading.pagehead}`}>Dividends</p>
                        <p className={`${cascading.pagedesc}`}>Allocate YUM here to earn a share of protocol earnings in the form of real yield.</p>
                    </div>
                    <div className={`row mx-0 ${cascading.balancesec}`}>
                        {detailcard.map((e) => (

                            <div className={`col-lg-4 col-xl-3 mb-4 ${cascading.col_3custom}`} >
                             <Balancecard datas={e}/>

                            </div>

                        ))}


                    </div>
                    <div className={`row mx-0`}>
                        <div className={`col-lg-5 col-xl-4`}>
                            {isconnect?
                            <>
                             <Yourallocation />
                           <Yourdividents />
                            </>
                            :
                            <Connectcard />
                            }

                          
                        </div>
                        <div className={`offset-xl-1 col-lg-7 col-xl-7`}>
                           <Currentepoch />
                           <Nextepoch />
                           <Timercard />
                           <Pendingrewards />

                        </div>

                    </div>

                </div>


            </div>



            <Footer />
        </>
    )
}
