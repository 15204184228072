import React, { useState } from 'react';
import cascading from '../assests/css/all.module.css';
import { useNavigate } from "react-router-dom";
import { Dropdown } from 'react-bootstrap';
import Footer from '../common/footer'
import { IoIosSearch } from "react-icons/io";
import Roundtablecard from '../components/roundtablecard';
import Header from '../common/header';
export default function Roundtable() {
    const navigate = useNavigate();
    const [tablecard, setTablecard] = useState([
        {
            pfimg:require("../assests/images/roundtable.png"),
            tablename: "WINR Protocol",
            type: [{
                names:"e-Gaming"
            },
            {
                names:"Infrastructure"
            }],
            pricevalue: "8.11M",
            swapvalue:"1.05M",
            userimg:[
                {img:require("../assests/images/timerclk.png")},
                {img:require("../assests/images/logomini.png")}
            ]

        },
        {
            pfimg:require("../assests/images/roundtable.png"),
            tablename: "WINR Protocol",
            type: [{
                names:"e-Gaming"
            },
            {
                names:"Infrastructure"
            }],
            pricevalue: "8.11M",
            swapvalue:"1.05M",
            userimg:[
                {img:require("../assests/images/timerclk.png")},
                {img:require("../assests/images/logomini.png")}
            ]

        },
        {
            pfimg:require("../assests/images/roundtable.png"),
            tablename: "WINR Protocol",
            type: [{
                names:"e-Gaming"
            },
            {
                names:"Infrastructure"
            }],
            pricevalue: "8.11M",
            swapvalue:"1.05M",
            userimg:[
                {img:require("../assests/images/timerclk.png")},
                {img:require("../assests/images/logomini.png")}
            ]
        },
      
    ])
    
    return (
        <>
         <Header/>
            <div className={`${cascading.innerpages} ${cascading.roundpage}`}>
                <div className={`container`}>
                    <div className={`row ${cascading.headrow}`}>
                        <p className={`${cascading.pagehead}`}>The Bakery</p>
                        <p className={`${cascading.pagedesc}`}>Meet the Official Partners of Donut.</p>
                    </div>



                    <div className={`${cascading.roundbg}`}>
                        <div className={`${cascading.searchsec}  `}>

                            <div className={`row ${cascading.searchcard} mx-0`}>

                                <div className={`col-lg-10`}>
                                    <div className={`${cascading.inputsec} my-3`}>
                                        <div className={`input-group mb-3`}>
                                            <input type="text" className={`form-control`} placeholder="Search" aria-label="Username" aria-describedby="basic-addon1" />
                                            <div className={`input-group-append`} >
                                                <button className={` ${cascading.inputspan}`} id="basic-addon1"><IoIosSearch /></button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className={` col-lg-2`}>
                                    <Dropdown className={`${cascading.dropdownstyle}`}>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            Hot
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className={`${cascading.dropdownmenustyle}`}>
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>


                            </div>

                        </div>
                        <div className={`row mx-0`}>
                            {tablecard.map((e) => (
                                <div className={`col-12 my-3`} onClick={()=>navigate("/bakerydetail")}>
                                <Roundtablecard  datas={e}/>
                                </div>
                            ))}

                        </div>
                    </div>



                </div>


            </div>



            <Footer />
        </>
    )
}
