import React, { useState } from 'react'
import cascading from '../assests/css/claimcardstyle.module.css';
import { BiRightArrowAlt } from "react-icons/bi";
import netimg from '../assests/images/roundtable.png'

//modals
import ComingSoonmodal from '../modals/comingSoonmodal';


export default function Balancecard(props) {

    const [modalcoming, setmodalcoming] = useState(false);

    return (
        <>
            <div className={`${cascading.claimcard}`}>
                <div className={`${cascading.toprow} mb-4`}>
                    <div>
                        <p className={`${cascading.cardname}`}>Claim</p>
                    </div>
                    <div className={`${cascading.rightsec}`}>
                        <div className={`${cascading.inputsec} `}>
                            <div className={`input-group`}>
                                <input type="text" className={`form-control`} placeholder="0" aria-label="Username" aria-describedby="basic-addon1" />
                                <div className={`input-group-append`} >
                                    <button className={` ${cascading.inputspan}`} id="basic-addon1">Max</button>
                                </div>
                            </div>
                        </div>
                       
                        {/* <button className={`btn ${cascading.btnstyle}`}>Buy</button> */}
                        <button className={`btn ${cascading.btnstyle}`}  onClick={()=>{setmodalcoming(true)}} >Buy</button>

                    </div>
                </div>
                <div className={`${cascading.swaprow}`}>
                    <div className={`${cascading.swapsec}`}>
                        <div>
                            <img src={netimg} alt='img' className={`img-fluid ${cascading.claimimg}`} />
                        </div>
                        <div>
                            <p className={`${cascading.netname}`}>GMBL</p>
                            <p className={`${cascading.netprice}`}>0</p>
                        </div>
                    </div>
                    <div className={`${cascading.swapsec}`}> 
                        <div>
                            <img src={netimg} alt='img' className={`img-fluid  ${cascading.claimimg}`} />
                        </div>
                        <div>
                            <p className={`${cascading.netname}`}>xGMBL</p>
                            <p className={`${cascading.netprice}`}>0</p>
                        </div>
                    </div>
                </div>
                <div className={`${cascading.btmsec}`}>
                    <div className={`${cascading.contrirow}`}>
                        <p>Your contribution</p>
                        <p>0 USDC.e</p>
                    </div>
                    {/* <button className={`btn ${cascading.btnstyle}`}>Claim</button> */}
                    <button className={`btn ${cascading.btnstyle}`}  onClick={()=>{setmodalcoming(true)}}>Claim</button>


                </div>
            </div>

            {modalcoming && <ComingSoonmodal show={modalcoming} onHide={() => { setmodalcoming(false); }} />}


        </>
    )
}
